import { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  Button,
  Alert,
  Grid,
  Stack,
  Paper,
  IconButton,
} from "@mui/material";
import { Delete, Close } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "100%",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  outline: "none",
  p: 4,
};

const EditLanguageModal = (props) => {
  // modal states
  const [localLangData, setLocalLangData] = useState([
    ...props.employeeData?.languages,
  ]);
  const [validations, setValidations] = useState({});
  const [multipleVal, setMultipleVal] = useState({});
  // other functions
  const handleSubmit = () => {
    if (isFormValid()) {
      props.setOpen({ ...props.open, editLanguages: false });
      props.setEmployeeData((prevState) => {
        return {
          ...prevState,
          languages: [...localLangData],
        };
      });
    }
  };

  const isFormValid = () => {
    const data = localLangData.map((lang) => lang.id);
    if (data.length > [...new Set(data)].length) {
      setMultipleVal({
        status: true,
        msg: "A language cannot be present multiple times",
      });
      return false;
    }
    return true;
  };

  const handleDelete = (index) => {
    setLocalLangData((prevState) => {
      if (prevState.length === 1) {
        setValidations({
          status: true,
          msg: "Atleast one language is required",
        });
        return [...prevState];
      }
      prevState.splice(index, 1);
      return [...prevState];
    });
  };

  return (
    <Modal open={props.open.editLanguages}>
      <Box style={style}>
        <Grid container sx={{ justifyContent: "space-around" }}>
          <Grid xs={8} sm={6} md={5} xl={4} lg={3} item>
            <Paper style={{ ...props.style, width: "100%" }} elevation={0}>
              <Box sx={{ padding: "10px" }}>
                <Grid container>
                  <Grid xs={11} sm={11} md={11} lg={11} xl={11} item>
                    <Box style={{ paddingBottom: "10px" }}>
                      <Typography variant="h5">Edit Languages</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    xs={1}
                    sm={1}
                    md={1}
                    lg={1}
                    xl={1}
                    item
                    sx={{ textAlign: "right" }}
                  >
                    <IconButton
                      style={{ paddingTop: "0px", paddingRight: "0px" }}
                      onClick={() =>
                        props.setOpen({ ...props.open, editLanguages: false })
                      }
                    >
                      <Close color="error" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Stack spacing={1}>
                  {localLangData?.map((lang, index) => (
                    <Grid container>
                      <Grid xs={10} sm={10} md={10} lg={10} xl={10} item>
                        <FormControl
                          error={validations?.status}
                          sx={{ width: "100%" }}
                        >
                          <Select
                            variant="outlined"
                            size="small"
                            value={lang.id}
                            onChange={(e) => {
                              setLocalLangData((prevState) => {
                                const lang = props.languageOptions.filter(
                                  (lang) => lang.id === e.target.value
                                );
                                prevState[index] = {
                                  ...prevState[index],
                                  ...lang[0],
                                };
                                return [...prevState];
                              });
                            }}
                          >
                            {props.languageOptions.map((lang) => (
                              <MenuItem value={lang.id}>{lang.name}</MenuItem>
                            ))}
                          </Select>
                          {validations?.status ? (
                            <FormHelperText>{validations?.msg}</FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>
                      <Grid
                        xs={1}
                        sm={1}
                        md={1}
                        lg={1}
                        xl={1}
                        item
                        sx={{ textAlign: "right" }}
                      >
                        <IconButton
                          size="small"
                          onClick={() => handleDelete(index)}
                        >
                          <Delete color="error" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  {multipleVal.status ? (
                    <Alert severity="error">{multipleVal.msg || ""}</Alert>
                  ) : null}
                </Stack>
                <Box sx={{ paddingTop: "10px" }}>
                  <Button variant="contained" onClick={(e) => handleSubmit()}>
                    Submit
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default EditLanguageModal;
