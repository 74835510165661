import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Alert,
  Grid,
  TextField,
  IconButton,
  OutlinedInput,
  InputAdornment,
  FormControl,
  FormHelperText,
  Snackbar,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "../../config/axios";
import { resolveErrMsg, getUserData } from "../../utils/utilities";
import { Close } from "@mui/icons-material";

const UserProfile = () => {
  const [userData, setUserData] = useState({});
  const [alert, setAlert] = useState({});
  const [validations, setValidations] = useState({});
  const [btnLoader, setBtnLoader] = useState(false);

  // other functions
  const closeAlertMessage = () => {
    setAlert({});
  };

  const handleChange = (field, value) => {
    setUserData((prevState) => ({
      ...prevState,
      [`${field}`]: value,
    }));
  };

  // API Services
  const getUserDetails = () => {
    const params = {
      id: getUserData().id,
    };

    axios
      .get("/user/details", { params })
      .then((res) => {
        if (res.status === 200) {
          setUserData(res.data.data || {});
        }
      })
      .catch((err) => console.log(err));
  };

  const updateUserDetails = () => {
    setBtnLoader(true);
    const params = {
      id: getUserData().id,
    };

    const body = {
      ...userData,
    };

    axios
      .put("user/update", body, { params })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status) {
            setAlert({
              variant: "success",
              msg: res.data.message,
              status: true,
            });
            setUserData(res.data.data || {});
          } else {
            if (res.data.type === "validation") {
              const tempVal = {};
              res.data.error?.forEach((err) => {
                tempVal[err.field] = resolveErrMsg(err.type);
              });
              setValidations(tempVal);
            }
            if (res.data.type === "error") {
              setAlert({
                variant: "error",
                msg: res.data.error?.message,
                status: true,
              });
            }
          }
          setBtnLoader(false);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <Grid container rowSpacing={2}>
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
        <Typography variant="body1" sx={{ fontSize: "20px" }} color="#474747">
          User Profile
        </Typography>
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
        <Paper elevation={0}>
          <Box sx={{ padding: "30px" }}>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
                <TextField
                  autoFocus
                  size="small"
                  fullWidth
                  label="Name"
                  value={userData.name || ""}
                  onChange={(e) => handleChange("name", e.target.value)}
                  error={validations.name}
                  helperText={validations.name || ""}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
                <TextField
                  size="small"
                  fullWidth
                  label="Email"
                  value={userData.email || ""}
                  onChange={(e) => handleChange("email", e.target.value)}
                  error={validations.email}
                  helperText={validations.email || ""}
                />
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                <LoadingButton variant="contained" onClick={updateUserDetails}>
                  Update
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={alert.status}
        onClose={closeAlertMessage}
        message={alert.msg}
        key={"system-message"}
        autoHideDuration={2000}
      >
        <Alert severity={alert.variant}>
          {alert.msg}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            sx={{ ml: 2 }}
            onClick={closeAlertMessage}
          >
            <Close fontSize="small" />
          </IconButton>
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default UserProfile;
