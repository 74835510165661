import { useState, useEffect } from "react";
import {
  Modal,
  Box,
  FormControl,
  MenuItem,
  FormHelperText,
  Typography,
  Alert,
  TextField,
  Button,
  Grid,
  Stack,
  Paper,
  IconButton,
  Select,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "100%",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  outline: "none",
  p: 4,
};

const AddExperienceModal = (props) => {
  const [localData, setLocalData] = useState({});
  const [totalValidation, setTotalValidation] = useState({
    status: false,
    msg: "",
  });
  const [durationValidation, setDurationValidation] = useState({
    status: false,
    msg: "",
  });
  const [techValidation, setTechValidation] = useState({
    state: false,
    msg: "",
  });

  // other functions
  const handleSubmit = () => {
    const durVal = validateDuration(localData.duration);
    const techVal = validateTech(localData.expertise);
    const expVal = totalExperienceValidation();
    if (durVal && techVal && expVal) {
      props.setEmployeeData((prevState) => {
        const tempData = prevState.experienceDetails;

        // to remove init state set for the purpose of rendering
        if (!tempData[0].duration && !tempData[0].tech.length) {
          tempData.splice(0, 1);
        }
        return {
          ...prevState,
          experienceDetails: [...tempData, localData],
        };
      });
      props.setOpen({ ...props.open, addExperience: false });
    }
  };

  const validateDuration = (dur) => {
    if (!dur || dur <= 0) {
      setDurationValidation({
        status: true,
        msg: "Please fill the data to submit",
      });
      return false;
    }
    return true;
  };

  const validateTech = (tech) => {
    if (!tech || !tech.length) {
      setTechValidation({
        status: true,
        msg: "Please fill the data to submit",
      });
      return false;
    }
    return true;
  };

  const totalExperienceValidation = () => {
    let total = 0;
    let totalTotal = props.employeeData.experience || 0;
    props.employeeData.experienceDetails.map((exp) => {
      if (!isNaN(parseInt(exp?.duration))) {
        total += parseInt(exp?.duration);
      }
    });
    total += parseInt(localData?.duration);
    if (total > totalTotal) {
      setTotalValidation({
        status: true,
        msg: "The Individual experiences exceed the total experience. \n Please make sure you enter correct data",
      });
      return false;
    }
    return true;
  };

  return (
    <Modal open={props.open.addExperience}>
      <Box style={style}>
        <Grid container sx={{ justifyContent: "space-around" }}>
          <Grid xs={8} sm={6} md={5} xl={4} lg={3} item>
            <Paper style={{ ...props.style, width: "100%" }} elevation={0}>
              <Box sx={{ padding: "10px" }}>
                <Grid container>
                  <Grid xs={11} sm={11} md={11} lg={11} xl={11} item>
                    <Box style={{ paddingBottom: "10px" }}>
                      <Typography variant="h5">Add Experience</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    xs={1}
                    sm={1}
                    md={1}
                    lg={1}
                    xl={1}
                    item
                    sx={{ textAlign: "right" }}
                  >
                    <IconButton
                      style={{ paddingTop: "0px", paddingRight: "0px" }}
                      onClick={() => {
                        props.setOpen({ ...props.open, addExperience: false });
                      }}
                    >
                      <Close color="error" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Stack spacing={1}>
                  <Grid container>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                      <Grid container>
                        <Grid xs={4} sm={4} md={4} lg={4} xl={4} item>
                          <TextField
                            style={{ widht: "100%" }}
                            size="small"
                            type="number"
                            error={durationValidation.status}
                            helperText={durationValidation.msg}
                            value={localData.duration || ""}
                            onChange={(e) =>
                              setLocalData((prevState) => {
                                if (parseInt(e.target.value) <= 0) {
                                  return { ...prevState };
                                }
                                return {
                                  ...prevState,
                                  duration: parseInt(e.target.value),
                                };
                              })
                            }
                          />
                        </Grid>
                        <Grid xs={8} sm={8} md={8} lg={8} xl={8} item>
                          <FormControl
                            error={techValidation.status}
                            style={{ width: "100%" }}
                          >
                            <Select
                              size="small"
                              helperText={techValidation.msg}
                              value={localData.tech || ""}
                              onChange={(e) =>
                                setLocalData((prevState) => ({
                                  ...prevState,
                                  tech: e.target.value,
                                }))
                              }
                              displayEmpty={true}
                              renderValue={() => {
                                const styles = {};
                                const value = localData.tech
                                  ? props.expertiseOptions?.filter(
                                      (opt) => opt.name === localData.tech
                                    )[0]
                                  : "Select";
                                if (value === "Select") {
                                  styles.color = "#919fa5";
                                }
                                return <div style={styles}>{value.name}</div>;
                              }}
                            >
                              {props.expertiseOptions?.map((skill) => (
                                <MenuItem value={skill.name}>
                                  {skill.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>
                              {techValidation.msg}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Stack>
                {totalValidation.status ? (
                  <Alert severity="error">{totalValidation.msg}</Alert>
                ) : null}
                <Box sx={{ paddingTop: "40px" }}>
                  <Button variant="contained" onClick={(e) => handleSubmit()}>
                    Submit
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddExperienceModal;
