import { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import axios from "../../config/axios";
import { LoadingButton } from "@mui/lab";
import {
  isSuperAdmin,
  resolveErrMsg,
  getUserData,
} from "../../utils/utilities";

const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  outline: "none",
  p: 4,
};

const role = [
  { name: "Super Admin", value: "true" },
  { name: "Employee ", value: "false" },
];

const LinkProfileModal = (props) => {
  const [userData, setUserData] = useState({ isSuperAdmin: false });
  const [btnLoader, setBtnLoader] = useState(false);
  const [validations, setValidations] = useState({});

  // other functions
  const handleCloseModal = () => {
    props.setOpen(false);
  };

  const handleChange = (field, value) => {
    setUserData((prevState) => ({
      ...prevState,
      [`${field}`]: value,
    }));
  };

  // API Services
  const handleAddUser = () => {
    setBtnLoader(true);
    setValidations({});
    const url = isSuperAdmin() ? "admin/create" : "user/create";
    const body = isSuperAdmin()
      ? { ...userData }
      : { ...userData, adminId: getUserData().id };

    axios
      .post(url, body)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status) {
            props.setAlert({
              variant: "success",
              msg: res.data.message,
              status: true,
            });
            handleCloseModal();
          } else {
            if (res.data.type === "validation") {
              const tempVal = {};
              res.data.error?.forEach((err) => {
                tempVal[err.field] = resolveErrMsg(err.type);
              });
              setValidations(tempVal);
            }
            if (res.data.type === "error") {
              props.setAlert({
                variant: "error",
                msg: res.data.error?.message,
                status: true,
              });
            }
          }
        }
        setBtnLoader(false);
        props.getList();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal open={props.open}>
      <Box style={style}>
        <Grid container sx={{ justifyContent: "space-around" }}>
          <Grid
            item
            xs={8}
            sm={6}
            md={6}
            xl={4}
            lg={4}
            sx={{ display: "flex" }}
          >
            <Paper style={{ ...props.style, width: "100%" }} elevation={0}>
              <Box sx={{ paddingLeft: "10px" }}>
                <Grid container>
                  <Grid xs={11} sm={11} md={11} lg={11} xl={11} item>
                    <Box style={{ paddingBottom: "10px", marginTop: "15px" }}>
                      <Typography variant="h5">Link Profile</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    xs={1}
                    sm={1}
                    md={1}
                    lg={1}
                    xl={1}
                    item
                    sx={{ textAlign: "right" }}
                  >
                    <IconButton onClick={handleCloseModal}>
                      <Close color="error" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ padding: "10px" }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      placeholder="Name"
                      size="small"
                      error={validations.name}
                      helperText={validations.name}
                      value={userData.name || ""}
                      onChange={(e) => handleChange("name", e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      placeholder="Email"
                      size="small"
                      error={validations.email}
                      helperText={validations.email}
                      value={userData.email || ""}
                      onChange={(e) => handleChange("email", e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Select
                      placeholder="Role"
                      size="small"
                      value={userData.isSuperAdmin || false}
                      onChange={(e) =>
                        handleChange("isSuperAdmin", e.target.value)
                      }
                      fullWidth
                    >
                      <MenuItem value={false}> Employee </MenuItem>
                      <MenuItem value={true}>Super Admin</MenuItem>
                    </Select>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "20px",
                    }}
                  >
                    <LoadingButton
                      loading={btnLoader}
                      variant="contained"
                      onClick={handleAddUser}
                    >
                      Add
                    </LoadingButton>
                    <Button
                      color="error"
                      variant="contained"
                      sx={{ marginLeft: "10px" }}
                      onClick={handleCloseModal}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default LinkProfileModal;
