import { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  FormControl,
  FormHelperText,
  MenuItem,
  Button,
  Grid,
  Stack,
  Paper,
  IconButton,
  Select,
  TextareaAutosize,
  Autocomplete,
  Chip,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import FormLabel from "../../../components/FormLabel";

const yearOptions = [
  {
    value: 0,
    label: "0 Year",
  },
  {
    value: 1,
    label: "1 Year",
  },
  {
    value: 2,
    label: "2 Years",
  },
  {
    value: 3,
    label: "3 Years",
  },
  {
    value: 4,
    label: "4 Years",
  },
  {
    value: 5,
    label: "5 Years",
  },
  {
    value: 6,
    label: "6 Years",
  },
  {
    value: 7,
    label: "7 Years",
  },
  {
    value: 8,
    label: "8 Years",
  },
  {
    value: 9,
    label: "9 Years",
  },
  {
    value: 10,
    label: "10 Years",
  },
];

const monthOptions = [
  {
    value: 1,
    label: "1 Month",
  },
  {
    value: 2,
    label: "2 Months",
  },
  {
    value: 3,
    label: "3 Months",
  },
  {
    value: 4,
    label: "4 Months",
  },
  {
    value: 5,
    label: "5 Months",
  },
  {
    value: 6,
    label: "6 Months",
  },
  {
    value: 7,
    label: "7 Months",
  },
  {
    value: 8,
    label: "8 Months",
  },
  {
    value: 9,
    label: "9 Months",
  },
  {
    value: 10,
    label: "10 Months",
  },
  {
    value: 11,
    label: "11 Months",
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  outline: "none",
  p: 4,
  maxHeight: "100%",
  overflow: "auto",
};

const AddProjectModal = (props) => {
  const [localData, setLocalData] = useState({});
  const [validations, setValidations] = useState({
    name: { status: false, msg: "" },
    teamSize: { status: false, msg: "" },
    duration: { status: false, msg: "" },
    description: { status: false, msg: "" },
    language: { status: false, msg: "" },
    role: { status: false, msg: "" },
    toolsAndTechnology: { status: false, msg: "" },
    link: { status: false, msg: "" },
    challenges: { status: false, msg: "" },
    responsibility: { status: false, msg: "" },
  });
  // other functions
  const handleValidationChange = (field) => {
    setValidations((prevState) => ({
      ...prevState,
      [`${field}`]: {
        status: true,
        msg: "Required!",
      },
    }));
  };

  const resetValidation = (field) => {
    setValidations((prevState) => ({
      ...prevState,
      [`${field}`]: {
        status: false,
        msg: "",
      },
    }));
  };

  const handleTextFieldChange = (value, field) => {
    setLocalData((prevState) => ({
      ...prevState,
      [`${field}`]: value,
    }));
  };

  const filterEmptyData = (obj) => {
    const tempObj = {};
    Object.keys(obj)?.forEach((key) => {
      if (obj[key]) {
        tempObj[key] = obj[key];
      }
    });
    return tempObj;
  };

  const handleSubmit = () => {
    if (isFormValid(localData)) {
      props.setEmployeeData((prevState) => {
        const tempData = prevState.projects || [];
        tempData.push(filterEmptyData(localData));
        return {
          ...prevState,
          projects: [...tempData],
        };
      });
      setLocalData({});
      props.resetValidationField("projects");
      props.setOpen({ ...props.open, addProject: false });
    }
  };

  const isFormValid = (data) => {
    let flag = true;
    if (!data.name || !data.name.length) {
      handleValidationChange("name");
      flag = false;
    } else {
      resetValidation("name");
    }
    // if (!data.teamSize || !data.teamSize < 0) {
    //   handleValidationChange("teamSize");
    //   flag = false;
    // } else {
    //   resetValidation("teamSize");
    // }
    // if (!data.years && !data.months) {
    //   setValidations((prevState) => ({
    //     ...prevState,
    //     duration: { status: true, msg: "Required!" },
    //   }));
    //   flag = false;
    // }
    if (!data.description || !data.description.length) {
      handleValidationChange("description");
      flag = false;
    } else {
      resetValidation("description");
    }
    return flag;
  };

  return (
    <Modal open={props.open.addProject}>
      <Box style={style}>
        <Grid container sx={{ justifyContent: "space-around" }}>
          <Grid xs={8} sm={6} md={5} xl={4} lg={3} item>
            <Paper style={{ ...props.style, width: "100%" }} elevation={0}>
              <Box sx={{ padding: "10px" }}>
                <Stack spacing={1}>
                  <Grid container>
                    <Grid xs={11} sm={11} md={11} lg={11} xl={11} item>
                      <Box style={{ paddingBottom: "10px" }}>
                        <Typography variant="h5">Add Project</Typography>
                      </Box>
                    </Grid>
                    <Grid
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      xl={1}
                      item
                      sx={{ textAlign: "right" }}
                    >
                      <IconButton
                        style={{ paddingTop: "0px", paddingRight: "0px" }}
                        onClick={() => {
                          props.setOpen({ ...props.open, addProject: false });
                          setLocalData({});
                        }}
                      >
                        <Close color="error" />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid container rowSpacing={1}>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                      <FormLabel title="Project Name" />
                      <TextField
                        variant="outlined"
                        size="small"
                        sx={{ width: "100%" }}
                        error={validations.name.status}
                        helperText={validations.name.msg}
                        value={localData.name}
                        onChange={(e) =>
                          handleTextFieldChange(e.target.value, "name")
                        }
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                      <FormLabel title="Project Description" />
                      <FormControl
                        error={validations.description.status}
                        style={{ width: "100%" }}
                      >
                        <TextareaAutosize
                          placeholder="Project Description"
                          className={
                            validations.description.status
                              ? "mui-textarea-error"
                              : "mui-textarea"
                          }
                          minRows={5}
                          value={localData.description}
                          onChange={(e) =>
                            handleTextFieldChange(e.target.value, "description")
                          }
                        ></TextareaAutosize>
                        {validations.description.status ? (
                          <FormHelperText
                            style={{
                              color: "#d32f2f",
                              marginLeft: "15px",
                              marginTop: 0,
                            }}
                          >
                            Required!
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                      <FormLabel title="Programming Language" />
                      <TextField
                        variant="outlined"
                        size="small"
                        sx={{ width: "100%" }}
                        error={validations.language.status}
                        helperText={validations.language.msg}
                        value={localData.language}
                        onChange={(e) =>
                          handleTextFieldChange(e.target.value, "language")
                        }
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                      <FormLabel title="Role" />
                      <TextField
                        variant="outlined"
                        size="small"
                        sx={{ width: "100%" }}
                        error={validations.role.status}
                        helperText={validations.role.msg}
                        value={localData.role}
                        onChange={(e) =>
                          handleTextFieldChange(e.target.value, "role")
                        }
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                      <FormLabel title="Responsibility" />
                      <FormControl
                        error={validations.responsibility.status}
                        style={{ width: "100%" }}
                      >
                        <TextareaAutosize
                          className={
                            validations.responsibility.status
                              ? "mui-textarea-error"
                              : "mui-textarea"
                          }
                          minRows={3}
                          value={localData.responsibility}
                          onChange={(e) =>
                            handleTextFieldChange(
                              e.target.value,
                              "responsibility"
                            )
                          }
                        ></TextareaAutosize>
                        {validations.responsibility.status ? (
                          <FormHelperText
                            style={{
                              color: "#d32f2f",
                              marginLeft: "15px",
                              marginTop: 0,
                            }}
                          >
                            Required!
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                      <FormLabel title="Tools & Technology" />
                      <Autocomplete
                        variant="outlined"
                        size="small"
                        sx={{ width: "100%" }}
                        multiple
                        error={validations.toolsAndTechnology.status}
                        helperText={validations.toolsAndTechnology.msg}
                        options={props.skillOptions}
                        getOptionLabel={(options) => options}
                        // value={localData.toolsAndTechnology}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              sx={{ fontWeight: "500" }}
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tools & tach"
                            placeholder="Favorites"
                          />
                        )}
                        onChange={(_, value) =>
                          handleTextFieldChange(value, "toolsAndTechnology")
                        }
                      />
                    </Grid>{" "}
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                      <FormLabel title="Reference Link" />

                      <FormControl
                        error={validations.link.status}
                        style={{ width: "100%" }}
                      >
                        <TextareaAutosize
                          className={
                            validations.link.status
                              ? "mui-textarea-error"
                              : "mui-textarea"
                          }
                          minRows={3}
                          value={localData.link}
                          onChange={(e) =>
                            handleTextFieldChange(e.target.value, "link")
                          }
                        ></TextareaAutosize>
                        {validations.link.status ? (
                          <FormHelperText
                            style={{
                              color: "#d32f2f",
                              marginLeft: "15px",
                              marginTop: 0,
                            }}
                          >
                            Required!
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>{" "}
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                      <FormLabel title="Challenges" />
                      <FormControl
                        error={validations.challenges.status}
                        style={{ width: "100%" }}
                      >
                        <TextareaAutosize
                          className={
                            validations.challenges.status
                              ? "mui-textarea-error"
                              : "mui-textarea"
                          }
                          minRows={3}
                          value={localData.challenges}
                          onChange={(e) =>
                            handleTextFieldChange(e.target.value, "challenges")
                          }
                        ></TextareaAutosize>
                        {validations.challenges.status ? (
                          <FormHelperText
                            style={{
                              color: "#d32f2f",
                              marginLeft: "15px",
                              marginTop: 0,
                            }}
                          >
                            Required!
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                      <Grid columnSpacing={1} container>
                        <Grid xs={4} sm={4} md={4} lg={4} xl={4} item>
                          <FormLabel title="Team Size" />
                          <TextField
                            variant="outlined"
                            type="number"
                            size="small"
                            sx={{ width: "100%" }}
                            error={validations.teamSize.status}
                            helperText={validations.teamSize.msg}
                            value={localData.teamSize || ""}
                            onChange={(e) => {
                              if (parseInt(e.target.value) <= 0) {
                                alert("must be a positive integer");
                                return;
                              }
                              handleTextFieldChange(
                                parseInt(e.target.value),
                                "teamSize"
                              );
                            }}
                          />
                        </Grid>
                        <Grid xs={8} sm={8} md={8} lg={8} xl={8} item>
                          <Grid container>
                            <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                              <FormLabel title="Duration" />
                            </Grid>
                            <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                              <FormControl
                                error={validations.duration.status}
                                style={{ width: "100%" }}
                              >
                                <Select
                                  size="small"
                                  style={{ width: "100%" }}
                                  value={localData.years}
                                  onChange={(e) =>
                                    handleTextFieldChange(
                                      e.target.value,
                                      "years"
                                    )
                                  }
                                >
                                  {yearOptions.map((d) => (
                                    <MenuItem value={d.value}>
                                      {d.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {validations.duration.status ? (
                                  <FormHelperText>
                                    {validations.duration.msg}
                                  </FormHelperText>
                                ) : null}
                              </FormControl>
                            </Grid>
                            <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                              <FormControl
                                error={validations.duration.status}
                                style={{ width: "100%" }}
                              >
                                <Select
                                  size="small"
                                  value={localData.months}
                                  onChange={(e) =>
                                    handleTextFieldChange(
                                      e.target.value,
                                      "months"
                                    )
                                  }
                                >
                                  {monthOptions.map((d) => (
                                    <MenuItem value={d.value}>
                                      {d.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {validations.duration.status ? (
                                  <FormHelperText>
                                    {validations.duration.msg}
                                  </FormHelperText>
                                ) : null}
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Stack>
                <Box sx={{ paddingTop: "20px" }}>
                  <Grid columnSpacing={1} container>
                    <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                      <Grid columnSpacing={1} container>
                        <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                          <Button
                            variant="contained"
                            style={{ width: "100%" }}
                            onClick={handleSubmit}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddProjectModal;
