import { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Stack,
  Paper,
  IconButton,
  Autocomplete,
  Chip,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "100%",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  outline: "none",
  p: 4,
};

const EditToolsModal = (props) => {
  // modal states
  const [localData, setLocalData] = useState([]);

  // other functions
  const handleSubmit = () => {
    props.setOpen({ ...props.open, editTools: false });
    props.setEmployeeData({ ...props.employeeData, tools: localData });
  };

  const handleToolNameChange = (data) => {
    const tools = data.map((tool) => ({
      name: tool.name,
      value: tool?.value ?? 75,
    }));
    setLocalData(tools);
  };

  useEffect(() => {
    setLocalData([...props.employeeData?.tools]);
    // eslint-disable-next-line
  }, []);

  return (
    <Modal open={true}>
      <Box style={style}>
        <Grid container sx={{ justifyContent: "space-around" }}>
          <Grid xs={8} sm={6} md={5} xl={4} lg={3} item>
            <Paper style={{ ...props.style, width: "100%" }} elevation={0}>
              <Box sx={{ padding: "10px" }}>
                <Grid container>
                  <Grid xs={11} sm={11} md={11} lg={11} xl={11} item>
                    <Box style={{ paddingBottom: "10px" }}>
                      <Typography variant="h5">Edit Tools</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    xs={1}
                    sm={1}
                    md={1}
                    lg={1}
                    xl={1}
                    item
                    sx={{ textAlign: "right" }}
                  >
                    <IconButton
                      style={{ paddingTop: "0px", paddingRight: "0px" }}
                      onClick={() =>
                        props.setOpen({ ...props.open, editTools: false })
                      }
                    >
                      <Close color="error" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Stack spacing={1}>
                  <Grid container mt={3} mb={3}>
                    <Grid xs={10} sm={10} md={10} lg={10} xl={10} item>
                      <Autocomplete
                        variant="outlined"
                        size="small"
                        multiple={true}
                        sx={{ width: "100%" }}
                        value={localData}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              sx={{ fontWeight: "500" }}
                              label={option.name}
                              {...getTagProps({ index })}
                              disabled={localData.length <= 1}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tools & tach"
                            placeholder="Favorites"
                          />
                        )}
                        options={props.toolsList}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) =>
                          option.name === value.name
                        }
                        onChange={(_, value) => handleToolNameChange(value)}
                      />
                    </Grid>
                  </Grid>
                </Stack>
                <Box sx={{ paddingTop: "10px" }}>
                  <Button variant="contained" onClick={(e) => handleSubmit()}>
                    Submit
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default EditToolsModal;
