import { useState, useEffect } from "react";
import axios from "../../../config/axios";
import {
  Modal,
  Box,
  Select,
  Typography,
  TextField,
  Snackbar,
  Alert,
  Button,
  Grid,
  Paper,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  outline: "none",
  p: 4,
};

const AddEditExpertiseModal = (props) => {
  const [expertise, setExpertise] = useState("");
  const [skills, setSkills] = useState([]);
  const [newSkill, setNewSkill] = useState("");

  // other functions
  const handleCloseModal = () =>
    props.setOpen((prevState) => ({
      ...prevState,
      addEditSkill: false,
    }));

  const addSkill = () => {
    if (newSkill) {
      setSkills([...skills, newSkill]);
      setNewSkill("");
    }
  };

  const removeSkill = (skill) => {
    const index = skills.indexOf(skill);
    const temp = [...skills];
    temp.splice(index, 1);
    setSkills([...temp]);
  };

  // API Services
  const handleEdit = () => {
    const name = props.expertiseOptions?.filter(
      (opt) => opt.id === expertise
    )[0]?.name;
    const body = {
      name,
      skills,
    };
    axios
      .put(`admin/expertises/${expertise}`, body)
      .then((res) => {
        if (res.status === 200) {
          props.setSkillOptions((prevState) => ({
            ...prevState,
            [`${expertise}`]: res.data.data?.skills || [],
          }));
          handleCloseModal();
          props.setAlertVariant("success");
          props.setDisplayAlert(true);
          props.setAlertMessage("Changes Saved Successfully");
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setSkills(props.skills);
    setExpertise(props.employeeData.expertise[0]);

    return () => {
      setSkills([]);
      setExpertise("");
    };
  }, []);

  return (
    <Modal open={props.open.addEditSkill}>
      <Box style={style}>
        <Grid container sx={{ justifyContent: "space-around" }}>
          <Grid
            item
            xs={8}
            sm={6}
            md={6}
            xl={4}
            lg={4}
            sx={{ display: "flex" }}
          >
            <Paper style={{ ...props.style, width: "100%" }} elevation={0}>
              <Box sx={{ paddingLeft: "10px" }}>
                <Grid container>
                  <Grid xs={11} sm={11} md={11} lg={11} xl={11} item>
                    <Box style={{ paddingBottom: "10px", marginTop: "15px" }}>
                      <Typography variant="h5">Manage Skill</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    xs={1}
                    sm={1}
                    md={1}
                    lg={1}
                    xl={1}
                    item
                    sx={{ textAlign: "right" }}
                  >
                    <IconButton onClick={handleCloseModal}>
                      <Close color="error" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ padding: "10px" }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {props.employeeData.expertise?.map((exp, index) => (
                      <Box style={{ marginBottom: "5px" }} key={exp}>
                        <Select
                          size={"small"}
                          style={{ width: "100%" }}
                          value={exp}
                          renderValue={() => {
                            const styles = {};
                            const value = exp
                              ? props.expertiseOptions.filter(
                                  (opt) => opt.id === exp
                                )[0]?.name
                              : "Select";
                            if (value === "Select") {
                              styles.color = "#919fa5";
                            }
                            return <div style={styles}>{value}</div>;
                          }}
                          disabled={true}
                        ></Select>
                      </Box>
                    ))}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={1}>
                      <Grid xs={9} sm={9} md={9} lg={9} xl={10} item>
                        <TextField
                          size="small"
                          fullWidth
                          sx={{ marginRight: "5px" }}
                          placeholder={"Skill Name"}
                          value={newSkill}
                          onChange={(e) => setNewSkill(e.target.value)}
                        />
                      </Grid>
                      <Grid xs={3} sm={3} md={3} lg={3} xl={2} item>
                        <Button
                          variant="contained"
                          sx={{ width: "100%" }}
                          onClick={addSkill}
                        >
                          Add
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={2}>
                      {skills.map((skill, index) => {
                        return (
                          <Grid item key={skill + index}>
                            <Box
                              component="div"
                              sx={{ border: "1px solid lightgray", pl: 1 }}
                            >
                              {skill}
                              <IconButton onClick={() => removeSkill(skill)}>
                                <Close />
                              </IconButton>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "15px",
                    }}
                  >
                    <Button variant="contained" onClick={handleEdit}>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddEditExpertiseModal;
