import { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Alert,
  Grid,
  TextField,
  Button,
  IconButton,
  FormControl,
  FormHelperText,
  Select,
  Divider,
  Slider,
  Rating,
  TextareaAutosize,
  Avatar,
  MenuItem,
  Slide,
  Snackbar,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { Edit, AddCircleOutline, Close, Delete } from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns as AdapterFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider as LocalizationProv } from "@mui/x-date-pickers/LocalizationProvider";
import { Circle, CircleOutlined } from "@mui/icons-material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import FormLabel from "../../components/FormLabel";
// ** modals ** //
import EditExpertiseModal from "./PageModals/EditExpertiseModal";
import AddExpertiseModal from "./PageModals/AddExpertiseModal";
import EditExperienceModal from "./PageModals/EditExperienceModal";
import AddExperienceModal from "./PageModals/AddExperienceModal";
import EditToolsModal from "./PageModals/EditToolsModal";
import AddToolModal from "./PageModals/AddToolModal";
import AddLanguageModal from "./PageModals/AddLanguageModal";
import EditLanguageModal from "./PageModals/EditLanguageModal";
import AddNRModal from "./PageModals/AddNationalityReligionModal.js";
import AddProjectModal from "./PageModals/AddProjectModal";
import EditProjectModal from "./PageModals/EditProjectModal";
import AddEditSkillModal from "./PageModals/AddEditSkillModal";
// ** modals ** //
import { withStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import "./create.css";
import axios from "../../config/axios";
import ProfilePreviewDialog from "./profilePreviewDialog";
import LoadingButton from "@mui/lab/LoadingButton";
import { isAdmin, getUserData } from "../../utils/utilities";

const styles = () => ({
  input: {
    height: 15,
    width: 100,
    textAlign: "center",
  },
});

const initData = {
  company: null,
  tools: [{ name: "Git And Bit Bucket", value: 75 }],
  languages: [{ name: "English", value: 3 }],
  experience: 1,
  experienceDetails: [{ duration: "", tech: "" }],
  expertise: [],
  timeFrom: new Date(),
  timeTill: new Date(),
  graduationDate: new Date(),
};

// page custom components
const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#1497D4",
  },
  "& .MuiRating-iconHover": {
    color: "#1497D4",
  },
});

const FormRow = ({ children }) => (
  // for form row
  <Box style={{ marginBottom: "20px" }}>
    <Grid spacing={3} container>
      {children}
    </Grid>
  </Box>
);

const SpaceBetweenWrapper = ({ children }) => (
  <Grid
    spacing={3}
    container
    style={{
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    {children}
  </Grid>
);

const formatDateYMD = (date) => {
  let month = date.getMonth() + 1;
  let d = date.getDate();
  if (month < 10) {
    month = `0${month}`;
  }
  if (d < 10) {
    d = `0${d}`;
  }
  if (isNaN(date.getFullYear()) || isNaN(d) || isNaN(month)) {
    return "";
  }
  return `${date.getFullYear()}-${month}-${d}`;
};

const Create = withStyles(styles)((props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const abortController = useRef(new AbortController());
  // APP states
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [employeeData, setEmployeeData] = useState({ ...initData });
  const [nationalityOptions, setNationalityOptions] = useState([]);
  const [expertiseOptions, setExpertiseOptions] = useState([]);
  const [religionOptions, setReligionOptions] = useState([]);
  const [skillOptions, setSkillOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [successMsg, setSuccessMsg] = useState(false);
  const [updateMsg, setUpdateMsg] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("success");
  const [showProfilePreviewDialog, setProfilePreviewDialog] = useState(false);
  const [previewSourcePath, setPreviewSourcePath] = useState("");
  const [generatingPreview, setGeneratingPreview] = useState(false);
  const [toolsList, setToolsList] = useState([]);

  useEffect(() => {
    if (!showProfilePreviewDialog) {
      setPreviewSourcePath("");
    }
  }, [showProfilePreviewDialog]);

  const { id } = useParams();
  // validation states
  const [totalExperienceValidation, setTotalExperienceValidation] = useState({
    status: false,
    msg: "",
  });
  const [validations, setValidations] = useState({});
  // modal states
  const [modalStatus, setModalStatus] = useState({
    editExpertise: false,
    addExpertise: false,
    addExperience: false,
    editExperience: false,
    addTool: false,
    editTools: false,
    addLanguage: false,
    editLanguages: false,
    addNR: false,
    editNR: false,
    addProject: false,
    editProjects: false,
  });

  // validation functions
  const validateTotalExperience = (data, index) => {
    console.log(data, "ashgcsghcg");
    let totalTotal = data.experience || 0;
    let total = data.experienceDetails[index]?.duration;
    console.log(total, "total");
    if (total > totalTotal) {
      setTotalExperienceValidation({
        status: true,
        msg: "Individual experiences is not grater then Total Experience ",
      });
      setTimeout(() => {
        setTotalExperienceValidation({ status: false, msg: "" });
      }, 7000);
      return false;
    }
    if (totalExperienceValidation.status) {
      setTotalExperienceValidation({ status: false, msg: "" });
    }
    return true;
  };

  // other functions
  const processTime = (time) => {
    const hours = time.split(":")[0];
    const minutes = time.split(":")[1];
    return new Date(new Date(new Date().setHours(hours)).setMinutes(minutes));
  };

  const formatProfileData = (data) => {
    if (data) {
      setSelectedSkills([...data.skills]);
      const empData = {
        company: data.company?._id,
        name: data.name,
        expertise: [data.expertise?._id],
        summary: data.summary,
        timeFrom: processTime(data.timeFrom),
        timeTill: processTime(data.timeTill),
        experience: data.experience,
        experienceDetails: data.experienceDetails,
        tools: data.tools,
        languages: data.languages.map((lang) => {
          const match = languageOptions.filter(
            (opt) => opt?.id === lang?.name
          )[0];
          return { value: lang.value, name: match?.name, id: match?.id };
        }),
        qualification: data.qualification,
        graduationDate: new Date(data.graduationDate),
        college: data.college,
        collegeCity: data.collegeCity,
        nationality: data.nationality?._id,
        religion: data.religion?._id,
        projects: data.projects,
        otherInfo: data.otherInfo,
        profilePic: data.profile_pic,
        id: data._id,
      };
      setEmployeeData((prevState) => ({ ...prevState, ...empData }));
    }
  };

  const handleSelectFieldChange = (value, field) => {
    setEmployeeData((prevState) => ({
      ...prevState,
      [`${field}`]: value,
    }));
  };

  const handleTextFieldChange = (value, field) => {
    setEmployeeData((prevState) => ({
      ...prevState,
      [`${field}`]: value,
    }));
  };

  const handleSkillToggle = (skill) => {
    const tempSkillArr = [...selectedSkills];
    if (selectedSkills.includes(skill)) {
      const index = selectedSkills.indexOf(skill);
      tempSkillArr.splice(index, 1);
      if (tempSkillArr.length) {
        resetValidationField("skill");
      }
      setSelectedSkills(tempSkillArr);
      return;
    }
    tempSkillArr.push(skill);
    if (tempSkillArr.length) {
      resetValidationField("skills");
    }
    setSelectedSkills(tempSkillArr);
  };

  const handleDelete = (index) => {
    setEmployeeData((prevState) => {
      const updatedDetails = prevState.experienceDetails.filter(
        (_, i) => i !== index
      );
      return { ...prevState, experienceDetails: updatedDetails };
    });
  };

  const handleAddEdit = (field, operation) => {
    switch (field) {
      case "expertise":
        if (operation === "edit") {
          setModalStatus({ ...modalStatus, editExpertise: true });
        }
        if (operation === "add") {
          setModalStatus({ ...modalStatus, addExpertise: true });
        }
        return;
      case "experience":
        if (operation === "edit") {
          setModalStatus({ ...modalStatus, editExperience: true });
        }
        if (operation === "add") {
          setModalStatus({ ...modalStatus, addExperience: true });
        }
        return;
      case "tools":
        if (operation === "edit") {
          setModalStatus({ ...modalStatus, editTools: true });
        }
        if (operation === "add") {
          setModalStatus({ ...modalStatus, addTool: true });
        }
        return;
      case "language":
        if (operation === "edit") {
          setModalStatus({ ...modalStatus, editLanguages: true });
        }
        if (operation === "add") {
          setModalStatus({ ...modalStatus, addLanguage: true });
        }
        return;
      case "N/R":
        if (operation === "add") {
          setModalStatus({ ...modalStatus, addNR: true });
        }
        return;
      case "project":
        if (operation === "edit") {
          setModalStatus({ ...modalStatus, editProjects: true });
        }
        if (operation === "add") {
          setModalStatus({ ...modalStatus, addProject: true });
        }
        return;
      default:
        return;
    }
  };

  const AddEditIcons = (props) => (
    <Grid container style={{ justifyContent: "flex-end" }}>
      <Grid
        xs={6}
        sm={6}
        md={6}
        lg={6}
        xl={6}
        item
        style={{ maxWidth: "30px" }}
      >
        {props.for === "N/R" ||
        props.for === "expertise" ||
        (props.for === "project" && !employeeData.projects?.length) ? (
          <></>
        ) : (
          <IconButton
            size="small"
            onClick={() => handleAddEdit(props.for, "edit")}
            sx={{ paddingTop: "0px" }}
          >
            <Edit color="primary" />
          </IconButton>
        )}
      </Grid>
      <Grid
        xs={6}
        sm={6}
        md={6}
        lg={6}
        xl={6}
        item
        style={{ maxWidth: "30px" }}
      >
        <IconButton
          size="small"
          onClick={() => handleAddEdit(props.for, "add")}
          // onClick={() => {
          //   setEmployeeData((prevState) => ({
          //     ...prevState,
          //     experienceDetails: [
          //       ...prevState.experienceDetails,
          //       { duration: "", tech: "" },
          //     ],
          //   }));
          // }}
          sx={{ paddingTop: "0px" }}
        >
          <AddCircleOutline color="primary" />
        </IconButton>
      </Grid>
    </Grid>
  );

  const processDurationString = (years, months) => {
    let str = "";
    if (years) {
      if (years === 1) {
        str += `${years} Year`;
      } else {
        str += `${years} Years`;
      }
    }
    if (months) {
      if (months === 1) {
        str += ` ${months} Month`;
      } else {
        str += ` ${months} Months`;
      }
    }
    return str;
  };

  const resetValidationField = (field) => {
    setValidations((prevState) => ({
      ...prevState,
      [`${field}`]: {
        status: false,
        msg: "",
      },
    }));
  };

  // API Services
  const saveEmployeeProfile = () => {
    const body = {
      ...employeeData,
      timeFrom: !isNaN(employeeData.timeFrom.getTime())
        ? `${employeeData.timeFrom.getHours()}:${employeeData.timeFrom.getMinutes()}`
        : "",
      timeTill: !isNaN(employeeData.timeTill.getTime())
        ? `${employeeData.timeTill.getHours()}:${employeeData.timeTill.getMinutes()}`
        : "",
      skills: selectedSkills,
      graduationDate: formatDateYMD(employeeData.graduationDate),
      languages: employeeData.languages.map((lang) => ({
        id: lang.id,
        value: lang.value,
      })),
      createdBy: getUserData().id,
    };

    if (!body.timeFrom || !body.timeTill) {
      return;
    }

    // if is created by admin need to assign the profile to that admin
    if (isAdmin()) {
      body.assignedToAdmins = [
        ...(body.assignedToAdmins || []),
        getUserData().id,
      ];
    }

    axios
      .post("employee-profile/create", body)
      .then((res) => {
        if (res.status === 200) {
          if (!res.data.status && res.data.type === "validation") {
            const val = {};
            res.data.error.forEach((err) => {
              let msg = "Required";
              if (err.field === "backendExpVal") {
                msg = err.message;
              }
              if (err.field === "profilePicUploadErr") {
                msg = err.message;
              }
              val[err.field] = { status: true, msg };
            });
            setValidations({ ...val });
          }
          if (res.data.status) {
            setSuccessMsg(true);
            setValidations({});
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const updateEmployeeProfile = () => {
    const params = { id: employeeData.id };

    const body = {
      ...employeeData,
      timeFrom: !isNaN(employeeData.timeFrom.getTime())
        ? `${employeeData.timeFrom.getHours()}:${employeeData.timeFrom.getMinutes()}`
        : "",
      timeTill: !isNaN(employeeData.timeTill.getTime())
        ? `${employeeData.timeTill.getHours()}:${employeeData.timeTill.getMinutes()}`
        : "",
      skills: selectedSkills,
      graduationDate: employeeData.graduationDate,
      languages: employeeData.languages.map((lang) => ({
        id: lang.id,
        value: lang.value,
      })),
    };

    delete body.id;

    if (!body.timeFrom || !body.timeTill) {
      return;
    }

    axios
      .put("employee-profile/update", body, { params })
      .then((res) => {
        if (res.status === 200) {
          if (!res.data.status && res.data.type === "validation") {
            const val = {};
            res.data.error.forEach((err) => {
              let msg = "Required";
              if (err.field === "backendExpVal") {
                msg = err.message;
              }
              if (err.field === "profilePicUploadErr") {
                msg = err.message;
              }
              val[err.field] = { status: true, msg };
            });
            setValidations({ ...val });
          }
          if (res.data.status) {
            setUpdateMsg(true);
            setValidations({});
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const displayAlertMessage = (variant, message) => {
    setDisplayAlert(true);
    setAlertMessage(message);
    setAlertVariant(variant);
  };

  const closeAlertMessage = () => {
    setDisplayAlert(false);
    setAlertMessage("");
  };

  const generatePreview = () => {
    setGeneratingPreview(true);

    const body = {
      ...employeeData,
      company: employeeData.company || "",
      name: employeeData.name || "",
      timeFrom: `${employeeData.timeFrom.getHours()}:${employeeData.timeFrom.getMinutes()}`,
      timeTill: `${employeeData.timeTill.getHours()}:${employeeData.timeTill.getMinutes()}`,
      skills: selectedSkills,
      graduationDate: formatDateYMD(employeeData.graduationDate),
      languages: employeeData.languages.map((lang) => ({
        value: lang.value,
        name: lang.name,
      })),
    };

    delete body.id;

    axios
      .post("admin/employee_profile/generate_preview", body, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        // eslint-disable-next-line
        if (res.headers["content-type"] == "application/pdf") {
          // display pdf preview
          let blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          setPreviewSourcePath(URL.createObjectURL(blob));
          setProfilePreviewDialog(true);
        } else {
          // handle error
          try {
            let response = JSON.parse(
              String.fromCharCode.apply(null, new Uint8Array(res.data))
            );

            if (!response.status) {
              displayAlertMessage("error", "Something went wrong");
            }
          } catch (error) {
            displayAlertMessage("error", "Something went wrong");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setGeneratingPreview(false);
      });
  };

  const fetchLanguageOptions = () => {
    axios
      .get("admin/languages")
      .then((res) => {
        if (res.status === 200) {
          setLanguageOptions(
            res.data.data.results.map((opt) => ({ name: opt.name, id: opt.id }))
          );
          setEmployeeData((prevState) => {
            const initlang = {
              name: res.data.data?.results[0]?.name,
              id: res.data.data?.results[0]?.id,
              value: 3,
            };
            return {
              ...prevState,
              languages: [initlang],
            };
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const fetchReligionOptions = () => {
    axios
      .get("/admin/active-religions")
      .then((res) => {
        if (res.status === 200) {
          setReligionOptions(
            res.data.result.map((opt) => ({ name: opt.name, id: opt.id }))
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const fetchToolsList = async () => {
    abortController.current.abort();
    abortController.current = new AbortController();
    try {
      const data = await axios.get("/admin/tools", {
        params: {
          pagination: "false",
        },
        signal: abortController.current.signal,
      });

      const resData = data.data;
      if (resData.status) {
        if (Array.isArray(resData.data)) {
          setToolsList(resData.data);
        }
      }
    } catch (err) {}
  };
  console.log(toolsList, "toolslist");

  const fetchExpertiseOptions = () => {
    axios
      .get("/admin/active-expertise")
      .then((res) => {
        if (res.status === 200) {
          let expertise = [];
          let skillOptions = {};
          res.data.result?.forEach((exp) => {
            // populate expertise options
            expertise.push({ name: exp?.name, id: exp?.id });
            // populate skills options
            skillOptions[exp.id] = exp.skills;
          });
          setExpertiseOptions([...expertise]);
          setSkillOptions({ ...skillOptions });
          // take first expertise as default to resolve input render issue
          setEmployeeData((prevState) => {
            return {
              ...prevState,
              expertise: [res.data.result[0]?.id],
            };
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const fetchCompanyOptions = () => {
    axios
      .get("/admin/companies")
      .then((res) => {
        if (res.status === 200) {
          setCompanyOptions(
            res.data.data.results.map((cmp) => ({ name: cmp.name, id: cmp.id }))
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const fetchNationalityOptions = () => {
    axios
      .get("/admin/active-nationalities")
      .then((res) => {
        if (res.status === 200) {
          setNationalityOptions(
            res.data.result.map((nat) => ({ name: nat.name, id: nat.id }))
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const getEmployeeProfile = (id) => {
    axios
      .get("/employee-profile/get-employee-profile", { params: { id } })
      .then((res) => {
        if (res.status === 200 && res.data.status) {
          formatProfileData(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchCompanyOptions();
    fetchNationalityOptions();
    fetchExpertiseOptions();
    fetchReligionOptions();
    fetchLanguageOptions();
    fetchToolsList();

    return () => {
      abortController.current.abort();
    };
  }, []);

  useEffect(() => {
    if (languageOptions.length) {
      if (id) {
        getEmployeeProfile(id);
      }
    }
    // eslint-disable-next-line
  }, [languageOptions]);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box style={{ marginBottom: "10px" }}>
          <Typography variant="h6" style={{ color: "#474747" }}>
            {id ? "Edit" : "Create New"} Profile
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Paper elevation={0} style={{ width: "100%", padding: "25px" }}>
          <Grid container spacing={0.5} style={{ flexWrap: "wrap-reverse" }}>
            <Grid xs={12} sm={12} md={12} xl={8} lg={8} item>
              <FormRow>
                <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                  <FormLabel title="Company Name" />
                  <FormControl
                    error={validations.company?.status}
                    style={{ width: "100%" }}
                  >
                    <Select
                      size={"small"}
                      onChange={(e) => {
                        handleSelectFieldChange(e.target.value, "company");
                        if (validations.company?.status) {
                          resetValidationField("company");
                        }
                      }}
                      value={employeeData.company}
                      displayEmpty={true}
                      renderValue={() => {
                        const styles = {};
                        const value = employeeData.company
                          ? companyOptions.filter(
                              (opt) => opt.id === employeeData.company
                            )[0]?.name
                          : "Select";
                        if (value === "Select") {
                          styles.color = "#919fa5";
                        }
                        return <div style={styles}>{value}</div>;
                      }}
                    >
                      {companyOptions.map((cmp) => (
                        <MenuItem value={cmp.id}>{cmp.name}</MenuItem>
                      ))}
                    </Select>
                    {validations.company?.status ? (
                      <FormHelperText>
                        {validations.company?.msg}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                  <FormLabel title="Employee Name" />
                  <TextField
                    id="name"
                    key="name"
                    size={"small"}
                    placeholder="Enter Name"
                    style={{ width: "100%" }}
                    error={validations.name?.status || false}
                    helperText={validations.name?.msg || ""}
                    value={employeeData.name}
                    onChange={(e) => {
                      handleTextFieldChange(e.target.value, "name");
                      if (validations.name?.status) {
                        resetValidationField("name");
                      }
                    }}
                  />
                </Grid>
              </FormRow>
              <FormRow>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                  <Box style={{ dispay: "flex" }}>
                    <SpaceBetweenWrapper>
                      <Grid xs={8} sm={9} md={9} lg={10} xl={10} item>
                        <FormLabel title="Current Designation (ex. Android developer, Laravel Developer, etc..)" />
                      </Grid>
                      <Grid xs={4} sm={3} md={3} lg={2} xl={2} item>
                        <AddEditIcons for={"expertise"} />
                      </Grid>
                    </SpaceBetweenWrapper>
                  </Box>
                  {employeeData.expertise?.map((exp, index) => (
                    <Box style={{ marginBottom: "5px" }}>
                      <FormControl
                        style={{ width: "100%" }}
                        error={validations.expertise?.status && !exp}
                      >
                        <Select
                          size={"small"}
                          style={{ width: "100%" }}
                          value={exp}
                          onChange={(e) =>
                            setEmployeeData((prevState) => {
                              const tempData = prevState.expertise;
                              tempData[index] = e.target.value;
                              setSelectedSkills([]);
                              return {
                                ...prevState,
                                expertise: [...tempData],
                              };
                            })
                          }
                          renderValue={() => {
                            const styles = {};
                            const value = exp
                              ? expertiseOptions.filter(
                                  (opt) => opt.id === exp
                                )[0]?.name
                              : "Select";
                            if (value === "Select") {
                              styles.color = "#919fa5";
                            }
                            return <div style={styles}>{value}</div>;
                          }}
                        >
                          {expertiseOptions.map((opt) => (
                            <MenuItem value={opt.id}>{opt.name}</MenuItem>
                          ))}
                        </Select>
                        {validations.expertise?.status && !exp ? (
                          <FormHelperText>
                            {validations.expertise?.msg}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Box>
                  ))}
                  {/*}
                          <FormHelperText></FormHelperText>
                        */}
                </Grid>
              </FormRow>
              <FormRow>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                  <FormLabel title="Profile Summary" />
                  <TextareaAutosize
                    className={
                      validations.summary?.status
                        ? "mui-textarea-error"
                        : "mui-textarea"
                    }
                    minRows={5}
                    style={{ width: "100%", padding: "10px" }}
                    error={validations.summary?.status || false}
                    helperText={validations.summary?.msg || ""}
                    value={employeeData.summary || ""}
                    onChange={(e) => {
                      handleTextFieldChange(e.target.value, "summary");
                      if (validations.summary?.status) {
                        resetValidationField("summary");
                      }
                    }}
                  />
                  {validations.summary?.status ? (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                        marginLeft: "15px",
                        marginTop: 0,
                      }}
                    >
                      {validations.summary?.msg}
                    </FormHelperText>
                  ) : null}
                </Grid>
              </FormRow>
              <FormRow>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                  <Box style={{ height: "29px" }}>
                    <FormLabel title="Time" />
                  </Box>
                  <Grid container spacing={1}>
                    <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          value={employeeData.timeFrom}
                          onChange={(value) => {
                            setEmployeeData((prevState) => ({
                              ...prevState,
                              timeFrom: value,
                            }));
                          }}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              helperText={params.error ? "Invalid format!" : ""}
                              style={{ width: "100%" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          value={employeeData.timeTill}
                          onChange={(value) => {
                            setEmployeeData((prevState) => ({
                              ...prevState,
                              timeTill: value,
                            }));
                          }}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              helperText={params.error ? "Invalid format!" : ""}
                              style={{ width: "100%" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Grid>
              </FormRow>
              <FormRow>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
                  <SpaceBetweenWrapper>
                    <Grid xs={8} sm={9} md={8} lg={8} xl={8} item>
                      <Grid container>
                        <Grid item>
                          <FormLabel title="Total Experience" />
                        </Grid>
                        <Grid item>
                          <TextField
                            variant="standard"
                            size="small"
                            type="number"
                            placeholder="00 Years"
                            InputProps={{
                              classes: {
                                input: classes.input,
                              },
                            }}
                            style={{ marginLeft: "5px" }}
                            error={validations.experience?.status || false}
                            helperText={validations.experience?.msg || ""}
                            value={employeeData.experience}
                            onChange={(e) =>
                              setEmployeeData((prevState) => {
                                const tempState = {
                                  ...prevState,
                                  experience: e.target.value
                                    ? Number(e.target.value)
                                    : "",
                                };
                                return tempState;
                              })
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      xs={4}
                      sm={3}
                      md={4}
                      lg={4}
                      xl={4}
                      item
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        size="small"
                        // onClick={() => handleAddEdit(props.for, "add")}
                        onClick={() => {
                          setEmployeeData((prevState) => ({
                            ...prevState,
                            experienceDetails: [
                              ...prevState.experienceDetails,
                              { duration: "", tech: "" },
                            ],
                          }));
                        }}
                        sx={{ paddingTop: "0px" }}
                      >
                        <AddCircleOutline color="primary" />
                      </IconButton>
                    </Grid>
                  </SpaceBetweenWrapper>
                  <Grid item>
                    <FormLabel title="Experience by expertise:" />
                  </Grid>

                  {Array.isArray(employeeData.experienceDetails) &&
                    employeeData?.experienceDetails?.map((data, index) => (
                      <Grid container mb={2}>
                        <Grid xs={3} sm={3} md={3} lg={3} xl={3} item>
                          <FormControl
                            error={
                              validations.duration?.status &&
                              !String(data.duration).length
                            }
                          >
                            <TextField
                              size={"small"}
                              type="number"
                              error={
                                validations.duration?.status &&
                                !String(data.duration).length
                              }
                              // helperText={validations.duration?.msg}
                              value={data.duration || ""}
                              onChange={(e) =>
                                setEmployeeData((prevState) => {
                                  const tempData =
                                    [...prevState.experienceDetails] || [];
                                  tempData[index] = {
                                    ...tempData[index],
                                    duration: e.target.value
                                      ? Number(e.target.value)
                                      : "",
                                  };
                                  const tempState = {
                                    ...prevState,
                                    experienceDetails: [...tempData],
                                  };
                                  if (
                                    !validateTotalExperience(tempState, index)
                                  ) {
                                    return { ...prevState };
                                  }
                                  return tempState;
                                })
                              }
                            />
                            {validations.duration?.status &&
                            !String(data.duration).length ? (
                              <FormHelperText>
                                {validations.duration?.msg}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>
                        <Grid xs={7} sm={7} md={7} lg={7} xl={7} item>
                          <FormControl
                            style={{ width: "100%", marginLeft: "5px" }}
                            error={validations.tech?.status && !data.tech}
                          >
                            <Select
                              size={"small"}
                              value={data?.tech}
                              displayEmpty={true}
                              onChange={(e) =>
                                setEmployeeData((prevState) => {
                                  const tempState = prevState.experienceDetails;

                                  tempState[index] = {
                                    ...tempState[index],
                                    tech: e.target.value,
                                  };
                                  return {
                                    ...prevState,
                                    experienceDetails: [...tempState],
                                  };
                                })
                              }
                              renderValue={() => {
                                const styles = {};
                                const value = data.tech
                                  ? expertiseOptions.filter(
                                      (opt) => opt.name === data.tech
                                    )[0]?.name
                                  : "Select";
                                if (value === "Select") {
                                  styles.color = "#919fa5";
                                }
                                return <div style={styles}>{value}</div>;
                              }}
                            >
                              {expertiseOptions.map((opt) => (
                                <MenuItem value={opt.name}>{opt.name}</MenuItem>
                              ))}
                            </Select>
                            {validations.tech?.status && !data.tech ? (
                              <FormHelperText>
                                {validations.tech?.msg}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>

                        {employeeData?.experienceDetails.length > 1 && (
                          <Grid
                            xs={2}
                            sm={2}
                            md={2}
                            lg={2}
                            xl={2}
                            item
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              size="small"
                              onClick={() => handleDelete(index)}
                            >
                              <Delete color="error" />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    ))}
                  {totalExperienceValidation.status ? (
                    <Alert severity="error">
                      {totalExperienceValidation.msg}
                    </Alert>
                  ) : null}
                  {validations.backendExpVal?.status ? (
                    <Alert severity="error">
                      {validations.backendExpVal?.msg}
                    </Alert>
                  ) : null}
                </Grid>
              </FormRow>

              <FormRow>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                  <SpaceBetweenWrapper>
                    <Grid item>
                      <FormLabel title="Skills" />
                    </Grid>
                    <Grid item>
                      <IconButton
                        size="small"
                        onClick={() =>
                          setModalStatus((prevState) => ({
                            ...prevState,
                            addEditSkill: true,
                          }))
                        }
                        sx={{ paddingTop: "0px" }}
                      >
                        <Edit color="primary" />
                      </IconButton>
                    </Grid>
                  </SpaceBetweenWrapper>
                  <Paper
                    style={{ width: "100%", backgroundColor: "#F9F9F9" }}
                    elevation={0}
                    sx={{ border: "1px solid #EEE" }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        padding: "12px",
                        flexWrap: "wrap",
                      }}
                    >
                      {skillOptions[employeeData.expertise[0]]?.map((skill) => (
                        <Button
                          variant={
                            selectedSkills.includes(skill)
                              ? "contained"
                              : "outlined"
                          }
                          style={{
                            margin: "8px",
                          }}
                          onClick={() => handleSkillToggle(skill)}
                        >
                          {skill}
                        </Button>
                      ))}
                    </Box>
                  </Paper>
                  {validations.skills?.status ? (
                    <Alert severity="error">{validations.skills?.msg}</Alert>
                  ) : null}
                </Grid>
              </FormRow>
              <FormRow>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
                  <SpaceBetweenWrapper>
                    <Grid xs={8} sm={8} md={8} lg={8} xl={8} item>
                      <FormLabel title="Tools To Handle Project" />
                    </Grid>
                    <Grid
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      item
                      style={{ textAlign: "right" }}
                    >
                      <AddEditIcons for="tools" />
                    </Grid>
                  </SpaceBetweenWrapper>
                  <Divider orientation="horizontal" />
                  {employeeData.tools?.map((tool, index) => (
                    <Box style={{ paddingTop: "5px" }} key={`${tool.name}`}>
                      <SpaceBetweenWrapper>
                        <Grid item>
                          <Typography variant="body2">{tool?.name}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2">{tool?.value}</Typography>
                        </Grid>
                      </SpaceBetweenWrapper>
                      <Slider
                        value={
                          employeeData?.tools[index]
                            ? employeeData?.tools[index].value
                            : 0
                        }
                        onChange={(e) => {
                          const tempData = employeeData?.tools;
                          if (employeeData?.tools.length) {
                            tempData[index] = {
                              ...tempData[index],
                              value: e.target.value,
                            };
                            setEmployeeData({
                              ...employeeData,
                              tools: tempData,
                            });
                          }
                        }}
                      />
                    </Box>
                  ))}
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
                  <SpaceBetweenWrapper>
                    <Grid xs={8} sm={8} md={8} lg={8} xl={8} item>
                      <FormLabel title="Language" />
                    </Grid>
                    <Grid
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      item
                      style={{ textAlign: "right" }}
                    >
                      <AddEditIcons for="language" />
                    </Grid>
                  </SpaceBetweenWrapper>
                  <Divider orientation="horizontal" />
                  <Box style={{ paddingTop: "5px" }}>
                    {employeeData.languages?.map((lang, langIndex) => (
                      <SpaceBetweenWrapper>
                        <Grid xs={6} sm={6} md={5} lg={3} xl={2} item>
                          <Typography variant="body2">{lang.name}</Typography>
                        </Grid>
                        <Grid xs={6} sm={6} md={5} lg={5} xl={5} item>
                          <StyledRating
                            precision={1}
                            icon={<Circle />}
                            emptyIcon={
                              <CircleOutlined style={{ color: "#1497D4" }} />
                            }
                            value={lang.value}
                            onChange={(e) => {
                              setEmployeeData((prevState) => {
                                const tempLangData = prevState?.languages;
                                tempLangData[langIndex] = {
                                  ...tempLangData[langIndex],
                                  value: parseInt(e.target.value),
                                };
                                return {
                                  ...prevState,
                                  languages: [...tempLangData],
                                };
                              });
                            }}
                          />
                        </Grid>
                      </SpaceBetweenWrapper>
                    ))}
                  </Box>
                </Grid>
              </FormRow>
              <FormRow>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                  <Grid container>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                      <FormLabel title="Qualifications" />
                    </Grid>
                    <Grid spacing={3} container>
                      <Grid xs={12} sm={12} md={6} lg={3} xl={3} item>
                        <TextField
                          placeholder="Enter Degree"
                          size="small"
                          style={{ width: "100%" }}
                          error={validations.qualification?.status || false}
                          helperText={validations.qualification?.msg || ""}
                          value={employeeData.qualification || ""}
                          onChange={(e) => {
                            handleTextFieldChange(
                              e.target.value,
                              "qualification"
                            );
                            if (validations.qualification?.status) {
                              resetValidationField("qualification");
                            }
                          }}
                        />
                      </Grid>
                      <Grid xs={12} sm={12} md={6} lg={3} xl={3} item>
                        <LocalizationProv dateAdapter={AdapterFns}>
                          <DesktopDatePicker
                            inputFormat="MM/dd/yyyy"
                            value={employeeData.graduationDate}
                            onChange={(value) =>
                              setEmployeeData((prevState) => ({
                                ...prevState,
                                graduationDate: value,
                              }))
                            }
                            renderInput={(params) => (
                              <TextField
                                size={"small"}
                                {...params}
                                style={{ width: "100%" }}
                              />
                            )}
                          />
                        </LocalizationProv>
                      </Grid>
                      <Grid xs={12} sm={12} md={6} lg={3} xl={3} item>
                        <TextField
                          placeholder="College Name"
                          size="small"
                          style={{ width: "100%" }}
                          error={validations.college?.status || false}
                          helperText={validations.college?.msg || ""}
                          value={employeeData.college || ""}
                          onChange={(e) => {
                            handleTextFieldChange(e.target.value, "college");
                            if (validations.college?.status) {
                              resetValidationField("college");
                            }
                          }}
                        />
                      </Grid>
                      <Grid xs={12} sm={12} md={6} lg={3} xl={3} item>
                        <TextField
                          placeholder="City Name"
                          size="small"
                          style={{ width: "100%" }}
                          error={validations.collegeCity?.status || false}
                          helperText={validations.collegeCity?.msg || ""}
                          value={employeeData.collegeCity || ""}
                          onChange={(e) => {
                            handleTextFieldChange(
                              e.target.value,
                              "collegeCity"
                            );
                            if (validations.collegeCity?.status) {
                              resetValidationField("collegeCity");
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </FormRow>
              <FormRow>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
                  <SpaceBetweenWrapper>
                    <Grid xs={8} sm={8} md={8} lg={8} xl={8} item>
                      <FormLabel title={"Nationality/Religion"} />
                    </Grid>
                    <Grid
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      item
                      style={{ textAlign: "right" }}
                    >
                      <AddEditIcons for="N/R" />
                    </Grid>
                  </SpaceBetweenWrapper>
                  <Grid spacing={3} container>
                    <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                      <FormControl
                        error={validations.nationality?.status}
                        style={{ width: "100%" }}
                      >
                        <Select
                          size="small"
                          value={employeeData.nationality || ""}
                          onChange={(e) => {
                            handleSelectFieldChange(
                              e.target.value,
                              "nationality"
                            );
                            if (validations.nationality?.status) {
                              resetValidationField("nationality");
                            }
                          }}
                          displayEmpty={true}
                          renderValue={() => {
                            const styles = {};
                            const value = employeeData.nationality
                              ? nationalityOptions.filter(
                                  (opt) => opt.id === employeeData.nationality
                                )[0]?.name
                              : "Select";
                            if (value === "Select") {
                              styles.color = "#919fa5";
                            }
                            return <div style={styles}>{value}</div>;
                          }}
                        >
                          {nationalityOptions.map((opt) => (
                            <MenuItem value={opt.id}>{opt.name}</MenuItem>
                          ))}
                        </Select>
                        {validations.nationality?.status ? (
                          <FormHelperText>
                            {validations.nationality?.msg}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                      <FormControl
                        error={validations.religion?.status}
                        style={{ width: "100%" }}
                      >
                        <Select
                          size="small"
                          style={{ width: "100%" }}
                          value={employeeData.religion || ""}
                          onChange={(e) => {
                            handleSelectFieldChange(e.target.value, "religion");
                            if (validations.religion?.status) {
                              resetValidationField("religion");
                            }
                          }}
                          displayEmpty={true}
                          renderValue={() => {
                            const styles = {};
                            const value = employeeData.religion
                              ? religionOptions.filter(
                                  (opt) => opt.id === employeeData.religion
                                )[0]?.name
                              : "Select";
                            if (value === "Select") {
                              styles.color = "#919fa5";
                            }
                            return <div style={styles}>{value}</div>;
                          }}
                        >
                          {religionOptions.map((opt) => (
                            <MenuItem value={opt.id}>{opt.name}</MenuItem>
                          ))}
                        </Select>
                        {validations.religion?.status ? (
                          <FormHelperText>
                            {validations.religion?.msg}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </FormRow>
              <FormRow>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                  <SpaceBetweenWrapper>
                    <Grid xs={8} sm={8} md={8} lg={8} xl={8} item>
                      <FormLabel title={"Projects"} />
                    </Grid>
                    <Grid
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      item
                      style={{ textAlign: "right" }}
                    >
                      <AddEditIcons for="project" />
                    </Grid>
                  </SpaceBetweenWrapper>
                  {employeeData.projects?.map((project) => (
                    <Box sx={{ paddingTop: "10px" }}>
                      <Paper
                        elevation={0}
                        style={{
                          backgroundColor: "#f3f3f3",
                          border: "0.5px solid",
                          borderColor: "#d9d9d9",
                          width: "100%",
                        }}
                      >
                        <Box style={{ padding: "10px" }}>
                          <SpaceBetweenWrapper>
                            <Grid item>
                              <Typography variant="body2">
                                {project.name}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Grid
                                container
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marignTop: "10px",
                                }}
                              >
                                <Grid item>
                                  <Typography variant="body2">
                                    {project.teamSize
                                      ? `Team Size: ${project.teamSize} `
                                      : null}
                                    {project.teamSize &&
                                    (project.years || project.months)
                                      ? "| "
                                      : null}
                                    {project.years || project.months
                                      ? `Time Span: ${processDurationString(
                                          project.years,
                                          project.months
                                        )}`
                                      : null}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography variant="body2">
                                    {project.language
                                      ? `Languge/s: ${project.language} `
                                      : null}
                                    {project.language && project.role
                                      ? "| "
                                      : null}
                                    {project.role
                                      ? `Role: ${project.role}`
                                      : null}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </SpaceBetweenWrapper>
                        </Box>
                        <Paper elevation={0}>
                          <Box
                            style={{ padding: "10px", whiteSpace: "pre-wrap" }}
                          >
                            {project.description}
                          </Box>
                        </Paper>
                      </Paper>
                    </Box>
                  ))}
                  {validations.projects?.status ? (
                    <Alert severity="error">{validations.projects?.msg}</Alert>
                  ) : null}
                </Grid>
              </FormRow>
              <FormRow>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                  <FormLabel title="Soft skills and other programming interest" />
                  <TextareaAutosize
                    className={
                      validations.otherInfo?.status
                        ? "mui-textarea-error"
                        : "mui-textarea"
                    }
                    minRows={5}
                    style={{ width: "100%", padding: "10px" }}
                    error={validations.otherInfo?.status || false}
                    helperText={validations.otherInfo?.msg || ""}
                    value={employeeData.otherInfo || ""}
                    onChange={(e) => {
                      handleTextFieldChange(e.target.value, "otherInfo");
                      if (validations.otherInfo?.status) {
                        resetValidationField("otherInfo");
                      }
                    }}
                  ></TextareaAutosize>
                  {validations.otherInfo?.status ? (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                        marginLeft: "15px",
                        marginTop: 0,
                      }}
                    >
                      {validations.otherInfo?.msg}
                    </FormHelperText>
                  ) : null}
                </Grid>
              </FormRow>
              <FormRow>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                  <Box style={{ display: "flex", width: "100%" }}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        if (id) {
                          updateEmployeeProfile();
                        } else {
                          saveEmployeeProfile();
                        }
                      }}
                    >
                      {id ? "update" : "create"}
                    </Button>
                    <>
                      <LoadingButton
                        variant="contained"
                        style={{ marginLeft: "10px", minWidth: "100px" }}
                        loading={generatingPreview}
                        onClick={generatePreview}
                      >
                        {generatingPreview ? "" : "Preview"}
                      </LoadingButton>
                    </>
                  </Box>
                </Grid>
              </FormRow>
            </Grid>
            <Grid xs={12} sm={12} md={12} xl={4} lg={4} item>
              <Grid container>
                <Grid xs={12} sm={12} md={12} xl={12} lg={12} item>
                  <Box style={{ textAlign: "center", width: "100%" }}>
                    <Typography variant="h6">Profile Picture</Typography>
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={12} xl={12} lg={12} item>
                  <Box
                    style={{
                      textAlign: "center",
                      width: "100%",
                      paddingTop: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Avatar
                      src={employeeData.profilePic || ""}
                      style={{
                        height: "100px",
                        width: "100px",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={12} xl={12} lg={12} item>
                  <Box style={{ textAlign: "center", paddingTop: "10px" }}>
                    <Box style={{ display: "inline-block" }}>
                      <input
                        id={"profile-pic"}
                        type="file"
                        hidden
                        onChange={(e) => {
                          if (e.target.files.length) {
                            const reader = new FileReader();
                            reader.readAsDataURL(e.target.files[0]);
                            reader.onload = () => {
                              resetValidationField("profilePic");
                              setEmployeeData((prevState) => ({
                                ...prevState,
                                profilePic: reader.result,
                              }));
                            };
                            reader.onerror = (error) => {
                              console.log(error);
                            };
                          }
                        }}
                      />
                      <Button
                        onClick={() => {
                          document.getElementById("profile-pic").click();
                        }}
                      >
                        {employeeData.profilePic?.length ? "Edit" : "Add"}
                      </Button>
                      <Button
                        onClick={() => {
                          setEmployeeData((prevState) => {
                            delete prevState.profilePic;
                            return { ...prevState };
                          });
                          // reset hidden file typed input.
                          document.getElementById("profile-pic").value = "";
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              {validations.profilePic?.status ? (
                <Alert severity="error">{validations.profilePic?.msg}</Alert>
              ) : null}
              {validations.profilePicUploadErr?.status ? (
                <Alert severity="error">
                  {validations.profilePicUploadErr?.msg}
                </Alert>
              ) : null}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {modalStatus.editExpertise ? (
        <EditExpertiseModal
          open={modalStatus}
          setOpen={setModalStatus}
          employeeData={employeeData}
          setEmployeeData={setEmployeeData}
          expertiseOptions={expertiseOptions}
        />
      ) : null}
      {modalStatus.addExpertise ? (
        <AddExpertiseModal
          open={modalStatus}
          setOpen={setModalStatus}
          employeeData={employeeData}
          setEmployeeData={setEmployeeData}
          fetchExpertiseOptions={fetchExpertiseOptions}
        />
      ) : null}
      {modalStatus.editExperience ? (
        <EditExperienceModal
          open={modalStatus}
          setOpen={setModalStatus}
          employeeData={employeeData}
          setEmployeeData={setEmployeeData}
          skillOptions={skillOptions[employeeData.expertise[0]]}
        />
      ) : null}
      {modalStatus.addExperience ? (
        <AddExperienceModal
          open={modalStatus}
          setOpen={setModalStatus}
          employeeData={employeeData}
          setEmployeeData={setEmployeeData}
          skillOptions={skillOptions[employeeData.expertise[0]]}
          expertiseOptions={expertiseOptions}
        />
      ) : null}
      <AddToolModal
        open={modalStatus}
        setOpen={setModalStatus}
        employeeData={employeeData}
        setEmployeeData={setEmployeeData}
        toolsList={toolsList}
        fetchToolsList={fetchToolsList}
      />
      {modalStatus.editTools ? (
        <EditToolsModal
          open={modalStatus}
          setOpen={setModalStatus}
          employeeData={employeeData}
          setEmployeeData={setEmployeeData}
          toolsList={toolsList}
        />
      ) : null}
      {modalStatus.addLanguage ? (
        <AddLanguageModal
          open={modalStatus}
          setOpen={setModalStatus}
          employeeData={employeeData}
          setEmployeeData={setEmployeeData}
          languageOptions={languageOptions}
          fetchLanguageOptions={fetchLanguageOptions}
        />
      ) : null}
      {modalStatus.editLanguages ? (
        <EditLanguageModal
          open={modalStatus}
          setOpen={setModalStatus}
          employeeData={employeeData}
          setEmployeeData={setEmployeeData}
          languageOptions={languageOptions}
        />
      ) : null}
      {modalStatus.addNR ? (
        <AddNRModal
          open={modalStatus}
          setOpen={setModalStatus}
          fetchReligionOptions={fetchReligionOptions}
          fetchNationalityOptions={fetchNationalityOptions}
        />
      ) : null}
      <AddProjectModal
        open={modalStatus}
        setOpen={setModalStatus}
        employeeData={employeeData}
        setEmployeeData={setEmployeeData}
        resetValidationField={resetValidationField}
        skillOptions={skillOptions[employeeData.expertise[0]]}
      />
      {modalStatus.editProjects ? (
        <EditProjectModal
          open={modalStatus}
          setOpen={setModalStatus}
          employeeData={employeeData}
          setEmployeeData={setEmployeeData}
          skillOptions={skillOptions[employeeData.expertise[0]]}
        />
      ) : null}
      {modalStatus.addEditSkill ? (
        <AddEditSkillModal
          open={modalStatus}
          setOpen={setModalStatus}
          employeeData={employeeData}
          expertiseOptions={expertiseOptions}
          skills={skillOptions[employeeData.expertise[0]]}
          setSkillOptions={setSkillOptions}
          setDisplayAlert={setDisplayAlert}
          setAlertMessage={setAlertMessage}
          setAlertVariant={setAlertVariant}
        />
      ) : null}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={successMsg}
        TransitionComponent={Slide}
        autoHideDuration={2000}
        onClose={() => {
          setSuccessMsg(false);
          navigate("/employee-profiles/list");
        }}
      >
        <Alert serverity="success">
          Employee Profile Created Successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={updateMsg}
        TransitionComponent={Slide}
        autoHideDuration={2000}
        onClose={() => {
          setUpdateMsg(false);
          // navigate("/employee-profiles/list");
        }}
      >
        <Alert serverity="success">
          Employee Profile Updated Successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={displayAlert}
        onClose={closeAlertMessage}
        message={displayAlert}
        key={"system-message"}
        autoHideDuration={2000}
      >
        <Alert severity={alertVariant}>
          {alertMessage}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            sx={{ ml: 2 }}
            onClick={closeAlertMessage}
          >
            <Close fontSize="small" />
          </IconButton>
        </Alert>
      </Snackbar>
      <ProfilePreviewDialog
        open={showProfilePreviewDialog}
        setOpen={setProfilePreviewDialog}
        sourcePath={previewSourcePath}
      />
    </Grid>
  );
});

export default Create;
