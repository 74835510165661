import { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Select,
  MenuItem,
  Button,
  Grid,
  Stack,
  Paper,
  IconButton,
} from "@mui/material";
import { Delete, Close } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "100%",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  outline: "none",
  p: 4,
};

const ExpertiseModal = (props) => {
  const [localData, setLocalData] = useState([""]);

  const handleSubmit = () => {
    const tempState = [...localData];
    if (!tempState.length) {
      tempState.push("");
    }
    props.setEmployeeData((prevState) => ({
      ...prevState,
      expertise: [...tempState],
    }));
    props.setOpen({ ...props.open, editExpertise: false });
  };

  const handleDelete = (index) => {
    setLocalData((prevState) => {
      const tempState = prevState;
      tempState.splice(index, 1);
      return [...tempState];
    });
  };

  useEffect(() => {
    if (props.employeeData.expertise)
      setLocalData([...props.employeeData.expertise]);
  }, []);

  return (
    <Modal open={props.open.editExpertise}>
      <Box style={style}>
        <Grid container sx={{ justifyContent: "space-around" }}>
          <Grid xs={8} sm={6} md={5} xl={4} lg={3} item>
            <Paper style={{ ...props.style, width: "100%" }} elevation={0}>
              <Box sx={{ padding: "10px" }}>
                <Grid container>
                  <Grid xs={11} sm={11} md={11} lg={11} xl={11} item>
                    <Box style={{ paddingBottom: "10px" }}>
                      <Typography variant="h5">Edit Expertise</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    xs={1}
                    sm={1}
                    md={1}
                    lg={1}
                    xl={1}
                    item
                    sx={{ textAlign: "right" }}
                  >
                    <IconButton
                      style={{ paddingTop: "0px", paddingRight: "0px" }}
                      onClick={() =>
                        props.setOpen({ ...props.open, editExpertise: false })
                      }
                    >
                      <Close color="error" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Stack spacing={1}>
                  {localData.map((exp, index) => (
                    <Grid container>
                      <Grid xs={10} sm={10} md={10} lg={10} xl={10} item>
                        <Select
                          size="small"
                          sx={{ width: "100%" }}
                          value={localData[index]}
                          onChange={(e) =>
                            setLocalData((prevState) => {
                              const tempState = prevState;
                              tempState[index] = e.target.value;
                              return [...tempState];
                            })
                          }
                        >
                          {props.expertiseOptions.map((opt) => (
                            <MenuItem value={opt.id}>{opt.name}</MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        item
                        sx={{ textAlign: "right" }}
                      >
                        <IconButton
                          size="small"
                          onClick={() => handleDelete(index)}
                        >
                          <Delete color="error" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                </Stack>
                <Box sx={{ paddingTop: "10px" }}>
                  <Button variant="contained" onClick={(e) => handleSubmit()}>
                    Submit
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ExpertiseModal;
