import { useState, useEffect } from "react";
import {
  Container,
  Box,
  Grid,
  OutlinedInput,
  Typography,
  Paper,
  Snackbar,
  Alert,
  Button,
  InputAdornment,
  IconButton,
  FormControl,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogActions,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import FormLabel from "../../components/FormLabel.js";
import axios from "../../config/axios.js";
import LoadingButton from "@mui/lab/LoadingButton";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [validations, setValidations] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [passwordResetLoader, setPasswordResetLoader] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // other functions
  const checkQuery = () => {
    if (!query.get("id")) {
      navigate("/login");
    }
  };

  const handleDialogOpen = (message) => {
    setDialogOpen(true);
    setDialogMessage(message);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const closeAlertMessage = () => {
    setDisplayAlert(false);
    setAlertMessage("");
    setAlertVariant("");
  };

  const muiAlert = (variant, message) => {
    setAlertVariant(variant);
    setAlertMessage(message);
    setDisplayAlert(true);
  };

  // API Services
  const resetPassword = () => {
    setValidations({});
    setPasswordResetLoader(true);

    const body = {
      newPassword,
      confirmNewPassword,
      emailDocId: query.get("id"),
    };

    axios
      .put("/auth/reset-password", body)
      .then((res) => {
        if (res.status === 200) {
          if (!res.data.status) {
            if (res.data.type === "validation") {
              res.data.error?.forEach((err) => {
                setValidations((prevState) => ({
                  ...prevState,
                  [`${err.field}`]: err.message,
                }));
              });
            }
            if (res.data.type === "error") {
              handleDialogOpen(res.data.message);
            }
            setPasswordResetLoader(false);
          } else {
            setPasswordResetLoader(false);
            muiAlert(
              "success",
              "Password reset successfully. You can now login using new password"
            );
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    checkQuery();
  }, []);

  return (
    <Box sx={{ backgroundColor: "#f4f6f9" }}>
      <Container sx={{ height: "100vh" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid sx={{ justifyContent: "center" }} container>
            <Grid xs={11} sm={8} md={7} lg={6} xl={6} item>
              <Paper sx={{ padding: "45px" }} elevation={0}>
                <Grid container>
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{ marginBottom: "30px" }}
                    item
                  >
                    <Typography variant="h5">Reset Password</Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{ marginBottom: "20px" }}
                    item
                  >
                    <Box>
                      <FormLabel title="New Password" />
                      <FormControl error={validations.newPassword} fullWidth>
                        <OutlinedInput
                          size="small"
                          type={showPassword ? "text" : "password"}
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setShowPassword((prevState) => !prevState)
                                }
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {validations.newPassword ? (
                          <FormHelperText>
                            {validations.newPassword}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{ marginBottom: "20px" }}
                    item
                  >
                    <Box>
                      <FormLabel title="Confirm New Password" />
                      <FormControl
                        error={validations.confirmNewPassword}
                        fullWidth
                      >
                        <OutlinedInput
                          size="small"
                          type={showConfirmPassword ? "text" : "password"}
                          value={confirmNewPassword}
                          onChange={(e) =>
                            setConfirmNewPassword(e.target.value)
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setShowConfirmPassword(
                                    (prevState) => !prevState
                                  )
                                }
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {validations.confirmNewPassword ? (
                          <FormHelperText>
                            {validations.confirmNewPassword}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                    <LoadingButton
                      loading={passwordResetLoader}
                      variant="contained"
                      onClick={resetPassword}
                    >
                      reset password
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Dialog
        fullScreen={fullScreen}
        open={dialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle>{dialogMessage}</DialogTitle>
        <DialogActions sx={{ justifyContent: "flex-start" }}>
          <Button variant="contained" onClick={handleDialogClose}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={displayAlert}
        onClose={() => {
          closeAlertMessage();
          navigate("/login");
        }}
        key={"system-message"}
        autoHideDuration={5000}
      >
        <Alert severity={alertVariant}>{alertMessage}</Alert>
      </Snackbar>
    </Box>
  );
};

export default ResetPassword;
