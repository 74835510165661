import { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  FormControl,
  FormHelperText,
  TextField,
  MenuItem,
  Button,
  Grid,
  Stack,
  Paper,
  IconButton,
  Select,
  TextareaAutosize,
  Autocomplete,
  Chip,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import FormLabel from "../../../components/FormLabel";

const yearOptions = [
  {
    value: 0,
    label: "0 Year",
  },
  {
    value: 1,
    label: "1 Year",
  },
  {
    value: 2,
    label: "2 Years",
  },
  {
    value: 3,
    label: "3 Years",
  },
  {
    value: 4,
    label: "4 Years",
  },
  {
    value: 5,
    label: "5 Years",
  },
  {
    value: 6,
    label: "6 Years",
  },
  {
    value: 7,
    label: "7 Years",
  },
  {
    value: 8,
    label: "8 Years",
  },
  {
    value: 9,
    label: "9 Years",
  },
  {
    value: 10,
    label: "10 Years",
  },
];

const monthOptions = [
  {
    value: 1,
    label: "1 Month",
  },
  {
    value: 2,
    label: "2 Months",
  },
  {
    value: 3,
    label: "3 Months",
  },
  {
    value: 4,
    label: "4 Months",
  },
  {
    value: 5,
    label: "5 Months",
  },
  {
    value: 6,
    label: "6 Months",
  },
  {
    value: 7,
    label: "7 Months",
  },
  {
    value: 8,
    label: "8 Months",
  },
  {
    value: 9,
    label: "9 Months",
  },
  {
    value: 10,
    label: "10 Months",
  },
  {
    value: 11,
    label: "11 Months",
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "100%",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  outline: "none",
  p: 4,
};

const AddProjectModal = (props) => {
  const [selectedProject, setSelectedProject] = useState("");
  const [projectOptions, setProjectOptions] = useState([]);
  const [localProject, setLocalProject] = useState({ toolsAndTechnology: [] });
  const [proIndex, setProIndex] = useState();
  const [validations, setValidations] = useState({
    selectedProject: { status: false, msg: "" },
    name: { status: false, msg: "" },
    teamSize: { status: false, msg: "" },
    years: { status: false, msg: "" },
    months: { status: false, msg: "" },
    description: { status: false, msg: "" },
    language: { status: false, msg: "" },
    role: { status: false, msg: "" },
    toolsAndTechnology: { status: false, msg: "" },
    link: { status: false, msg: "" },
    challenges: { status: false, msg: "" },
    responsibility: { status: false, msg: "" },
  });

  // other functions
  const handleDelete = () => {
    props.setEmployeeData((prevState) => {
      const tempState = prevState.projects;
      tempState.splice(proIndex, 1);
      return {
        ...prevState,
        projects: [...tempState],
      };
    });
    closeModal();
  };

  const handleValidationChange = (field) => {
    setValidations((prevState) => ({
      ...prevState,
      [`${field}`]: {
        status: true,
        msg: "Required",
      },
    }));
  };

  const resetValidation = (field) => {
    setValidations((prevState) => ({
      ...prevState,
      [`${field}`]: {
        status: false,
        msg: "",
      },
    }));
  };

  const isFormValid = (data) => {
    let flag = true;
    if (!data.name || !data.name.length) {
      handleValidationChange("name");
      flag = false;
    } else {
      resetValidation("name");
    }
    // if (!data.teamSize || !data.teamSize < 0) {
    //   handleValidationChange("teamSize");
    //   flag = false;
    // } else {
    //   resetValidation("teamSize");
    // }
    // if (!data.years && !data.months) {
    //   handleValidationChange("years");
    //   handleValidationChange("months");
    //   flag = false;
    // } else {
    //   resetValidation("years");
    //   resetValidation("months");
    // }
    if (!data.description || !data.description.length) {
      handleValidationChange("description");
      flag = false;
    } else {
      resetValidation("description");
    }
    return flag;
  };

  const filterEmptyData = (obj) => {
    const tempObj = {};
    Object.keys(obj)?.forEach((key) => {
      if (obj[key]) {
        tempObj[key] = obj[key];
      }
    });
    return tempObj;
  };

  const handleSubmit = () => {
    if (isFormValid(localProject)) {
      props.setEmployeeData((prevState) => {
        const tempState = prevState.projects;
        tempState[proIndex] = filterEmptyData(localProject);
        return {
          ...prevState,
          projects: [...tempState],
        };
      });
      closeModal();
      setLocalProject({});
    }
  };

  const closeModal = () => {
    props.setOpen({ ...props.open, editProjects: false });
  };

  const handleTextFieldChange = (field, value) => {
    if (!selectedProject.length) {
      handleValidationChange("selectedProject");
      return;
    }
    setLocalProject((prevState) => ({
      ...prevState,
      [`${field}`]: value,
    }));
  };

  useEffect(() => {
    const proOpts = props.employeeData?.projects?.map((pro) => pro.name);

    if (proOpts && proOpts.length) {
      setProjectOptions(proOpts);
      if (proOpts.length === 1) {
        setLocalProject(props.employeeData?.projects[0]);
        setSelectedProject(props.employeeData?.projects[0]?.name);
        setProIndex(0);
      }
    }
  }, []);

  return (
    <Modal open={props.open.editProjects}>
      <Box style={style}>
        <Grid container sx={{ justifyContent: "space-around" }}>
          <Grid xs={8} sm={6} md={5} xl={4} lg={3} item>
            <Paper style={{ ...props.style, width: "100%" }} elevation={0}>
              <Box sx={{ padding: "10px" }}>
                <Stack spacing={1}>
                  <Grid container>
                    <Grid xs={11} sm={11} md={11} lg={11} xl={11} item>
                      <Box style={{ paddingBottom: "10px" }}>
                        <Typography variant="h5">Edit Project</Typography>
                      </Box>
                    </Grid>
                    <Grid
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      xl={1}
                      item
                      sx={{ textAlign: "right" }}
                    >
                      <IconButton
                        style={{ paddingTop: "0px", paddingRight: "0px" }}
                        onClick={() =>
                          props.setOpen({ ...props.open, editProjects: false })
                        }
                      >
                        <Close color="error" />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <FormLabel title="Project: " />
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                      <FormControl
                        error={validations.selectedProject.status}
                        style={{ width: "100%" }}
                      >
                        <Select
                          size="small"
                          value={localProject.name || ""}
                          onChange={(e) => {
                            setLocalProject(
                              props.employeeData?.projects?.filter(
                                (pro) => pro.name === e.target.value
                              )[0]
                            );
                            setSelectedProject(e.target.value);
                            setProIndex(
                              props.employeeData?.projects
                                ?.map((pro) => pro.name)
                                .indexOf(e.target.value)
                            );
                            if (validations.selectedProject.status) {
                              resetValidation("selectedProject");
                            }
                          }}
                          displayEmpty={true}
                          renderValue={() => {
                            const styles = {};
                            const value = localProject.name
                              ? projectOptions.filter(
                                  (opt) => opt === localProject.name
                                )[0]
                              : "Select";
                            if (value === "Select") {
                              styles.color = "#919fa5";
                            }
                            return <div style={styles}>{value}</div>;
                          }}
                        >
                          {projectOptions.map((opt) => (
                            <MenuItem value={opt}>{opt}</MenuItem>
                          ))}
                        </Select>
                        {validations.selectedProject.status ? (
                          <FormHelperText>
                            {validations.selectedProject.msg}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container rowSpacing={1}>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                      <FormLabel title="Project Name" />
                      <TextField
                        variant="outlined"
                        size="small"
                        sx={{ width: "100%" }}
                        errro={validations.name.status}
                        helperText={validations.name.msg}
                        value={localProject.name || ""}
                        onChange={(e) =>
                          handleTextFieldChange("name", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                      <FormLabel title="Project Description" />
                      <FormControl
                        error={validations.description.status}
                        style={{ width: "100%" }}
                      >
                        <TextareaAutosize
                          placeholder="Project Description"
                          className="mui-textarea"
                          minRows={5}
                          value={localProject.description || ""}
                          onChange={(e) =>
                            handleTextFieldChange("description", e.target.value)
                          }
                        ></TextareaAutosize>
                        {validations.description.status ? (
                          <FormHelperText>
                            {validations.description.msg}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                      <FormLabel title="Programming Language" />
                      <TextField
                        variant="outlined"
                        size="small"
                        sx={{ width: "100%" }}
                        error={validations.language.status}
                        helperText={validations.language.msg}
                        value={localProject.language}
                        onChange={(e) =>
                          handleTextFieldChange("language", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                      <FormLabel title="Role" />
                      <TextField
                        variant="outlined"
                        size="small"
                        sx={{ width: "100%" }}
                        error={validations.role.status}
                        helperText={validations.role.msg}
                        value={localProject.role}
                        onChange={(e) =>
                          handleTextFieldChange("role", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                      <FormLabel title="Responsibility" />
                      <FormControl
                        error={validations.responsibility.status}
                        style={{ width: "100%" }}
                      >
                        <TextareaAutosize
                          className={
                            validations.responsibility.status
                              ? "mui-textarea-error"
                              : "mui-textarea"
                          }
                          minRows={3}
                          value={localProject.responsibility}
                          onChange={(e) =>
                            handleTextFieldChange(
                              "responsibility",
                              e.target.value
                            )
                          }
                        ></TextareaAutosize>
                        {validations.responsibility.status ? (
                          <FormHelperText
                            style={{
                              color: "#d32f2f",
                              marginLeft: "15px",
                              marginTop: 0,
                            }}
                          >
                            Required!
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                      <FormLabel title="Tools & Technology" />

                      <Autocomplete
                        variant="outlined"
                        size="small"
                        value={localProject.toolsAndTechnology || []}
                        sx={{ width: "100%" }}
                        multiple
                        error={validations.toolsAndTechnology.status}
                        helperText={validations.toolsAndTechnology.msg}
                        options={props.skillOptions}
                        getOptionLabel={(option) => option}
                        renderTags={(tagValue, getTagProps) => {
                          return tagValue.map((option, index) => (
                            <Chip
                              sx={{ fontWeight: "500" }}
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ));
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tools & tach"
                            placeholder="Favorites"
                          />
                        )}
                        onChange={(_, value) => {
                          handleTextFieldChange("toolsAndTechnology", value);
                        }}
                      />
                    </Grid>{" "}
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                      <FormLabel title="Reference Link" />
                      <FormControl
                        error={validations.link.status}
                        style={{ width: "100%" }}
                      >
                        <TextareaAutosize
                          className={
                            validations.link.status
                              ? "mui-textarea-error"
                              : "mui-textarea"
                          }
                          minRows={3}
                          value={localProject.link}
                          onChange={(e) =>
                            handleTextFieldChange("link", e.target.value)
                          }
                        ></TextareaAutosize>
                        {validations.link.status ? (
                          <FormHelperText
                            style={{
                              color: "#d32f2f",
                              marginLeft: "15px",
                              marginTop: 0,
                            }}
                          >
                            Required!
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>{" "}
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                      <FormLabel title="Challenges" />
                      <FormControl
                        error={validations.challenges.status}
                        style={{ width: "100%" }}
                      >
                        <TextareaAutosize
                          className={
                            validations.challenges.status
                              ? "mui-textarea-error"
                              : "mui-textarea"
                          }
                          minRows={3}
                          value={localProject.challenges}
                          onChange={(e) =>
                            handleTextFieldChange("challenges", e.target.value)
                          }
                        ></TextareaAutosize>
                        {validations.challenges.status ? (
                          <FormHelperText
                            style={{
                              color: "#d32f2f",
                              marginLeft: "15px",
                              marginTop: 0,
                            }}
                          >
                            Required!
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                      <Grid columnSpacing={1} container>
                        <Grid xs={4} sm={4} md={4} lg={4} xl={4} item>
                          <FormLabel title="Team Size" />
                          <TextField
                            variant="outlined"
                            size="small"
                            type="number"
                            sx={{ width: "100%" }}
                            errro={validations.teamSize.status}
                            helperText={validations.teamSize.msg}
                            value={localProject.teamSize || ""}
                            onChange={(e) => {
                              if (parseInt(e.target.value) <= 0) {
                                return;
                              }
                              handleTextFieldChange(
                                "teamSize",
                                isNaN(parseInt(e.target.value))
                                  ? ""
                                  : parseInt(e.target.value)
                              );
                            }}
                          />
                        </Grid>
                        <Grid xs={8} sm={8} md={8} lg={8} xl={8} item>
                          <Grid container>
                            <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                              <FormLabel title="Time Span" />
                            </Grid>
                            <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                              <FormControl
                                error={validations.years.status}
                                style={{ width: "100%" }}
                              >
                                <Select
                                  size="small"
                                  value={String(localProject.years)}
                                  onChange={(e) =>
                                    handleTextFieldChange(
                                      "years",
                                      e.target.value
                                    )
                                  }
                                >
                                  {yearOptions.map((opt) => (
                                    <MenuItem value={opt.value}>
                                      {opt.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {validations.years.status ? (
                                  <FormHelperText>
                                    {validations.years.msg}
                                  </FormHelperText>
                                ) : null}
                              </FormControl>
                            </Grid>
                            <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                              <FormControl
                                error={validations.months.status}
                                style={{ width: "100%" }}
                              >
                                <Select
                                  size="small"
                                  value={String(localProject.months)}
                                  onChange={(e) =>
                                    handleTextFieldChange(
                                      "months",
                                      e.target.value
                                    )
                                  }
                                >
                                  {monthOptions.map((opt) => (
                                    <MenuItem value={opt.value}>
                                      {opt.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {validations.months.status ? (
                                  <FormHelperText>
                                    {validations.months.msg}
                                  </FormHelperText>
                                ) : null}
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Stack>
                <Box sx={{ paddingTop: "20px" }}>
                  <Grid columnSpacing={1} container>
                    <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                      <Grid columnSpacing={1} container>
                        <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                          <Button
                            variant="contained"
                            style={{ width: "100%" }}
                            onClick={handleSubmit}
                          >
                            Submit
                          </Button>
                        </Grid>
                        <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                          <Button
                            variant="contained"
                            color="error"
                            style={{ width: "100%" }}
                            onClick={handleDelete}
                            disabled={selectedProject.length <= 0}
                          >
                            Delete
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddProjectModal;
