import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import Slide from "@mui/material/Slide";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./profilePreviewDialog.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProfilePreviewDialog({ open, setOpen, sourcePath }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpen(false);
  };

  const downloadFile = (uri, name) => {
    let link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <Dialog
        fullScreen
        className="preview_profile_dialog"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div
          style={{
            backgroundColor: "#2a2e34",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: isMobile ? "0px" : "10px 0px",
          }}
        >
          <Typography sx={{ ml: 2, flex: 1 }} variant="body1" component="div">
            Profile Preview
          </Typography>
          <Button
            variant="text"
            className="download_btn"
            style={{
              color: "white",
              textTransform: "none",
              fontWeight: "light",
              marginRight: "10px",
            }}
            startIcon={<DownloadIcon />}
            disableRipple
            onClick={() => {downloadFile(sourcePath,"profile_preview")}}
          >
            Download
          </Button>
          <IconButton color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
        <div
          style={{ backgroundColor: "#2a2e34", height: "100%", width: "100%" }}
        >
          <iframe
            src={sourcePath+'#toolbar=0'}
            type="application/pdf"
            height="100%"
            width="100%"
            className="preview_dialog_iframe"
          ></iframe>
        </div>
      </Dialog>
    </div>
  );
}
