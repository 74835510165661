export const getUserData = () => {
  const user = localStorage.getItem("cvg_user");
  if (user) {
    return JSON.parse(user);
  }
  return {};
};

export const isAdmin = () => {
  if (getUserData().isAdmin) {
    return true;
  }
  return false;
};

export const isSuperAdmin = () => {
  if (getUserData().isAdmin && getUserData().isSuper) {
    return true;
  }
  return false;
};

export const resolveErrMsg = (type) => {
  const errMsgType = type?.split(".")?.[1] || "";
  switch (errMsgType) {
    case "required":
      return "Required!";
    case "empty":
      return "Required!";
    case "email":
      return "Must be a valid email address!";
    default:
      return "";
  }
};
