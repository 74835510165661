import { Typography } from "@mui/material";

const FormLabel = (props) => (
  // for form labels
  <Typography
    variant="subtitle2"
    style={{ marginBottom: "5px", ...props.style }}
  >
    {props.title}
  </Typography>
);

export default FormLabel;
