import React, { useState } from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'

export default function ColorPicker({ setColor, color = { r: '241', g: '112', b: '19', a: '1' }, popoverStyle = {}, colorBoxStyle = {} }) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  let handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  let handleClose = () => {
    setDisplayColorPicker(false);
  };

  let handleChange = (color) => {
    setColor(color.rgb);
  };

  const styles = reactCSS({
    'default': {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: `rgba(${ color.r }, ${ color.g }, ${ color.b }, ${ color.a })`,
      },
      swatch: {
        padding: '2px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.2)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  return (
    <div>
      <div style={ styles.swatch } onClick={ handleClick }>
        <div style={{ ...styles.color, ...colorBoxStyle }} />
      </div>
      { 
        displayColorPicker 
        ? <div style={{ ...styles.popover, ...popoverStyle }}>
            <div style={ styles.cover } onClick={ handleClose }/>
            <SketchPicker color={ color } onChange={ handleChange } />
          </div> 
        : null 
      }
    </div>
  );
}