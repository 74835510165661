import { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Alert,
  MenuItem,
  TextField,
  Button,
  Grid,
  Stack,
  Paper,
  IconButton,
  Select,
} from "@mui/material";
import { Delete, Close } from "@mui/icons-material";

const style = {
  top: "50%",
  left: "50%",
  maxHeight: "100%",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  outline: "none",
  p: 4,
};

const EditExperienceModal = (props) => {
  const [localData, setLocalData] = useState([]);
  const [validation, setValidation] = useState({});
  // other functions
  const handleSubmit = () => {
    if (isFormValid()) {
      props.setEmployeeData((prevState) => ({
        ...prevState,
        experienceDetails: [...localData],
      }));
      props.setOpen({ ...props.open, editExperience: false });
    }
  };

  const handleDelete = (index) => {
    setLocalData((prevState) => {
      const tempData = prevState;
      tempData.splice(index, 1);
      // to just render
      if (!tempData.length) {
        tempData.push({ duration: "", tech: "" });
      }
      return [...tempData];
    });
  };

  const isFormValid = () => {
    let total = 0;
    localData.forEach((exp) => {
      total += exp.duration;
    });
    if (total > props.employeeData.experience) {
      setValidation({
        status: true,
        msg: "Sum of individual experiences can not be greater than total experience. Please enter correct data",
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    setLocalData([...props.employeeData.experienceDetails]);
  }, []);

  return (
    <Modal open={props.open.editExperience}>
      <Box style={style}>
        <Grid container sx={{ justifyContent: "space-around" }}>
          <Grid xs={8} sm={6} md={5} xl={4} lg={3} item>
            <Paper style={{ ...props.style, width: "100%" }} elevation={0}>
              <Box sx={{ padding: "10px" }}>
                <Grid container>
                  <Grid xs={11} sm={11} md={11} lg={11} xl={11} item>
                    <Box style={{ paddingBottom: "10px" }}>
                      <Typography variant="h5">Edit Experience</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    xs={1}
                    sm={1}
                    md={1}
                    lg={1}
                    xl={1}
                    item
                    sx={{ textAlign: "right" }}
                  >
                    <IconButton
                      style={{ paddingTop: "0px", paddingRight: "0px" }}
                      onClick={() =>
                        props.setOpen({ ...props.open, editExperience: false })
                      }
                    >
                      <Close color="error" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Stack spacing={1}>
                  {localData.map((exp, index) => (
                    <Grid container>
                      <Grid xs={11} sm={11} md={11} lg={11} xl={11} item>
                        <Grid container>
                          <Grid xs={4} sm={4} md={4} lg={4} xl={4} item>
                            <TextField
                              sx={{ width: "100%" }}
                              size="small"
                              type="number"
                              value={exp.duration}
                              onChange={(e) =>
                                setLocalData((prevState) => {
                                  if (parseInt(e.target.value) <= 0) {
                                    return { ...prevState };
                                  }
                                  const tempData = prevState;
                                  tempData[index] = {
                                    ...tempData[index],
                                    duration: parseInt(e.target.value),
                                  };
                                  return [...tempData];
                                })
                              }
                            />
                          </Grid>
                          <Grid xs={8} sm={8} md={8} lg={8} xl={8} item>
                            <Select
                              sx={{ width: "100%" }}
                              size="small"
                              value={exp.tech || ""}
                              onChange={(e) =>
                                setLocalData((prevState) => {
                                  const tempData = prevState;
                                  tempData[index] = {
                                    ...tempData[index],
                                    tech: e.target.value,
                                  };
                                  return [...tempData];
                                })
                              }
                              displayEmpty={true}
                              renderValue={() => {
                                const styles = {};
                                const value = exp.tech
                                  ? props.skillOptions?.filter(
                                      (opt) => opt === exp.tech
                                    )[0]
                                  : "Select";
                                if (value === "Select") {
                                  styles.color = "#919fa5";
                                }
                                return <div style={styles}>{value}</div>;
                              }}
                            >
                              {props.skillOptions?.map((opt) => (
                                <MenuItem value={opt}>{opt}</MenuItem>
                              ))}
                            </Select>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={1}
                        sm={1}
                        md={1}
                        lg={1}
                        xl={1}
                        item
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          size="small"
                          onClick={() => handleDelete(index)}
                        >
                          <Delete color="error" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                </Stack>
                {validation.status ? (
                  <Alert severity="error">{validation.msg}</Alert>
                ) : null}
                <Box sx={{ paddingTop: "10px" }}>
                  <Button variant="contained" onClick={() => handleSubmit()}>
                    Submit
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default EditExperienceModal;
