import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app";
import Login from "./pages/auth/login";
import CreateEmployeeProfile from "./pages/employeeProfile/Create";
import LanguageList from "./pages/language/languageList";
import ExpertiseList from "./pages/expertise/expertiseList";
import CompanyList from "./pages/company/companyList";
import NationalityList from "./pages/nationality/nationalityList";
import ReligionList from "./pages/religion/religionList";
import EmployeeProfileList from "./pages/employeeProfile/List";
import ResetPassword from "./pages/auth/ResetPassword";
import ForgotPassword from "./pages/auth/ForgotPassword";
import UserList from "./pages/users/userList";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider } from "./hooks/auth";
import { RequireAuth } from "./utils/RequireAuth";
import { NotFound } from "./pages/NotFound";
import ChangePassword from "./pages/auth/changePassword";
import UserProfile from "./pages/users/userProfile";
import ToolsList from "./pages/toolsAndTech/ToolsList";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <BrowserRouter>
      <Routes>
        <Route index path="/login" element={<Login />} />
        <Route index path="/reset-password" element={<ResetPassword />} />
        <Route
          index
          path="/login/forgot-password"
          element={<ForgotPassword />}
        />
        <Route
          path="/"
          element={
            <RequireAuth>
              <App />
            </RequireAuth>
          }
        >
          <Route
            path="/"
            element={<Navigate to="/employee-profiles/list" replace />}
          />
          <Route
            path="/employee-profiles/list"
            element={
              <RequireAuth>
                <EmployeeProfileList />
              </RequireAuth>
            }
          />
          <Route
            path="/employee-profiles/create"
            element={
              <RequireAuth>
                <CreateEmployeeProfile />
              </RequireAuth>
            }
          />
          <Route
            path="/employee-profiles/edit/:id"
            element={
              <RequireAuth>
                <CreateEmployeeProfile />
              </RequireAuth>
            }
          />
          <Route
            path="/companies"
            element={
              <RequireAuth>
                <CompanyList />
              </RequireAuth>
            }
          />
          <Route
            path="/languages"
            element={
              <RequireAuth>
                <LanguageList />
              </RequireAuth>
            }
          />
          <Route
            path="/expertises"
            element={
              <RequireAuth>
                <ExpertiseList />
              </RequireAuth>
            }
          />
          <Route
            path="/nationalities"
            element={
              <RequireAuth>
                <NationalityList />
              </RequireAuth>
            }
          />
          <Route
            path="/religions"
            element={
              <RequireAuth>
                <ReligionList />
              </RequireAuth>
            }
          />
          <Route
            path="/users"
            element={
              <RequireAuth>
                <UserList />
              </RequireAuth>
            }
          />
          <Route
            path="/change-password"
            element={
              <RequireAuth>
                <ChangePassword />
              </RequireAuth>
            }
          />
          <Route
            path="/user-profile"
            element={
              <RequireAuth>
                <UserProfile />
              </RequireAuth>
            }
          />
          <Route
            path="/tools"
            element={
              <RequireAuth>
                <ToolsList />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </AuthProvider>
);

reportWebVitals();
