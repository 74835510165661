import { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  IconButton,
  MenuItem,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import axios from "../../../config/axios";
import { LoadingButton } from "@mui/lab";
import {
  isSuperAdmin,
  resolveErrMsg,
  getUserData,
} from "../../../utils/utilities";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "100%",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  outline: "none",
  p: 4,
};

const LinkProfileModal = (props) => {
  const [userData, setUserData] = useState({ isAdmin: false });
  const [btnLoader, setBtnLoader] = useState(false);
  const [validations, setValidations] = useState({});
  const [optionLoading, setOptionLoading] = useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState(["User", "Admin"]);

  // other functions
  const handleCloseModal = () => {
    props.setOpen(false);
  };

  const handleChange = (field, value) => {
    setUserData((prevState) => ({
      ...prevState,
      [`${field}`]: value,
    }));
  };

  const resolveLink = () => {
    isSuperAdmin()
      ? userData.isAdmin
        ? handleLinkProfileToAdmin()
        : handleLinkProfileToUser()
      : handleLinkProfileToUser();
  };

  // API Services
  const handleLinkProfileToUser = () => {
    setBtnLoader(true);
    setValidations({});
    const body = {
      email: userData.email || "",
      name: userData.name || "",
      profileId: props.profileId,
      adminId: getUserData().id,
    };
    axios
      .put("/employee-profile/assign-to-user", body)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status) {
            props.setAlertVariant("success");
            props.setAlertMessage("Profile Linked Successfully!");
            props.setAlert(true);
            handleCloseModal();
            props.getList();
          } else {
            if (res.data.type === "validation") {
              const tempVal = {};
              res.data.error?.forEach((err) => {
                tempVal[err.field] = resolveErrMsg(err.type);
              });
              setValidations(tempVal);
            }
            if (res.data.type === "error") {
              props.setAlertVariant("error");
              props.setAlertMessage(res.data.error?.message);
              props.setAlert(true);
            }
          }
        }
        setBtnLoader(false);
      })
      .catch((err) => console.log(err));
  };

  const handleLinkProfileToAdmin = () => {
    setBtnLoader(true);
    setValidations({});
    const body = {
      email: userData.email || "",
      name: userData.name || "",
      profileId: props.profileId,
    };
    axios
      .put("/employee-profile/assign-to-admin", body)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status) {
            props.setAlertVariant("success");
            props.setAlertMessage("Profile Linked Successfully!");
            props.setAlert(true);
            handleCloseModal();
            props.getList();
          } else {
            if (res.data.type === "validation") {
              const tempVal = {};
              res.data.error?.forEach((err) => {
                tempVal[err.field] = resolveErrMsg(err.type);
              });
              setValidations(tempVal);
            }
            if (res.data.type === "error") {
              props.setAlertVariant("error");
              props.setAlertMessage(res.data.error?.message);
              props.setAlert(true);
            }
          }
        }
        setBtnLoader(false);
      })
      .catch((err) => console.log(err));
  };

  const getAllUserOptions = () => {
    setOptionLoading(true);
    axios
      .get("/admin/all-users-list")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status) {
            setUserOptions(res.data.data || []);
          }
          setOptionLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const getUserOptions = () => {
    setOptionLoading(true);
    axios
      .get("/user/user-options")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status) {
            setUserOptions(res.data.data || []);
          }
          setOptionLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (isSuperAdmin()) {
      getAllUserOptions();
    } else {
      getUserOptions();
    }
    return () => {
      props.setProfileId("");
    };
  }, []);

  return (
    <Modal open={props.open}>
      <Box style={style}>
        <Grid container sx={{ justifyContent: "space-around" }}>
          <Grid
            item
            xs={8}
            sm={6}
            md={6}
            xl={4}
            lg={4}
            sx={{ display: "flex" }}
          >
            <Paper style={{ ...props.style, width: "100%" }} elevation={0}>
              <Box sx={{ paddingLeft: "10px" }}>
                <Grid container>
                  <Grid xs={11} sm={11} md={11} lg={11} xl={11} item>
                    <Box style={{ paddingBottom: "10px", marginTop: "15px" }}>
                      <Typography variant="h5">Link Profile</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    xs={1}
                    sm={1}
                    md={1}
                    lg={1}
                    xl={1}
                    item
                    sx={{ textAlign: "right" }}
                  >
                    <IconButton onClick={handleCloseModal}>
                      <Close color="error" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ padding: "10px" }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      placeholder="Name"
                      size="small"
                      error={validations.name}
                      helperText={validations.name}
                      value={userData.name || ""}
                      onChange={(e) => handleChange("name", e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      placeholder="Email"
                      size="small"
                      error={validations.email}
                      helperText={validations.email}
                      value={userData.email || ""}
                      onChange={(e) => handleChange("email", e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{ marginTop: "10px" }}
                  >
                    <TextField
                      select
                      fullWidth
                      label="Role"
                      size="small"
                      value={userData.isAdmin ? "Admin" : "User"}
                      onChange={(e) =>
                        handleChange("isAdmin", e.target.value === "Admin")
                      }
                      sx={{ marginTop: "5px" }}
                    >
                      {roleOptions?.map((role) => (
                        <MenuItem value={role}>{role}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{ marginTop: "10px" }}
                  >
                    <Typography variant="body2">
                      Link to existing user?
                    </Typography>
                    <TextField
                      select
                      fullWidth
                      label={"Users"}
                      size="small"
                      value={
                        userOptions?.filter(
                          (opt) =>
                            opt.email === userData.email &&
                            opt.name === userData.name
                        )?.[0]?.email || ""
                      }
                      onChange={(e) =>
                        e.target.value
                          ? setUserData((prevState) => ({
                              ...prevState,
                              email: e.target.value,
                              name:
                                userOptions?.filter(
                                  (opt) => opt.email === e.target.value
                                )?.[0]?.name || "",
                              isAdmin:
                                userOptions?.filter(
                                  (opt) => opt.email === e.target.value
                                )?.[0]?.isAdmin || false,
                            }))
                          : null
                      }
                      sx={{ marginTop: "5px" }}
                    >
                      {optionLoading ? (
                        <MenuItem>Loading...</MenuItem>
                      ) : userOptions?.length ? (
                        userOptions?.map((opt) => (
                          <MenuItem value={opt.email}>{opt.name}</MenuItem>
                        ))
                      ) : (
                        <MenuItem>No Options</MenuItem>
                      )}
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "20px",
                    }}
                  >
                    <LoadingButton
                      loading={btnLoader}
                      variant="contained"
                      onClick={resolveLink}
                    >
                      Link
                    </LoadingButton>
                    <Button
                      color="error"
                      variant="contained"
                      sx={{ marginLeft: "10px" }}
                      onClick={handleCloseModal}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default LinkProfileModal;
