import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  ImageListItem,
  Container,
  Grid,
  Typography,
  Snackbar,
  Alert,
  Box,
} from "@mui/material";
import loginImage from "../../assets/login.svg";
import axios from "../../config/axios";
import LoadingButton from "@mui/lab/LoadingButton";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [validations, setValidations] = useState({});
  const [sendMailLoader, setSendMailLoader] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");

  // other functions
  const closeAlertMessage = () => {
    setDisplayAlert(false);
    setAlertMessage("");
    setAlertVariant("");
  };

  const muiAlert = (variant, message) => {
    setAlertVariant(variant);
    setAlertMessage(message);
    setDisplayAlert(true);
  };

  // API Services
  const handleSendEmail = () => {
    setSendMailLoader(true);
    setValidations({});
    const body = {
      email,
    };
    axios.post("/auth/send-reset-password-mail", body).then((res) => {
      if (res.status === 200) {
        if (res.data.status) {
          setSendMailLoader(false);
          muiAlert(
            "success",
            "Mail send successfully!. Please check your inbox"
          );
        } else {
          if (res.data.type === "validation") {
            const tempVal = {};
            res.data.error?.map((err) => {
              tempVal[`${err.field}`] = err.message;
            });
            setValidations(tempVal);
            setSendMailLoader(false);
          }
        }
      }
    });
  };

  return (
    <Box sx={{ backgroundColor: "#F6F7FA", minHeight: "100vh" }}>
      <Container sx={{ backgroundColor: "inherit", pb: 8 }}>
        <Typography
          component="h1"
          sx={{
            color: "#D8E1EE",
            py: 7,
            textAlign: "center",
            fontSize: {
              xs: 20,
              md: 30,
            },
          }}
        >
          <b>NLS EMPLOYEE PROFILE PORTAL</b>
        </Typography>
        <Card
          elevation={0}
          sx={{
            mx: {
              sm: 3,
              md: 7,
            },
          }}
        >
          <CardContent sx={{ p: 0, paddingBottom: "0px !important" }}>
            <Grid container>
              <Grid
                item
                xs={0}
                sm={6}
                md={7}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#F9FAFB",
                  padding: 3,
                }}
              >
                <ImageListItem sx={{ display: { xs: "none", sm: "block" } }}>
                  <img src={loginImage} alt="login" loading="lazy" />
                </ImageListItem>
              </Grid>
              <Grid item xs={12} sm={6} md={5} p={5}>
                <Box>
                  <Typography
                    variant="h6"
                    component="h1"
                    sx={{ color: "#555555", py: 3, textAlign: "center" }}
                  >
                    <b>Forgot Password</b>
                  </Typography>
                  <TextField
                    id="demo-helper-text-misaligned-no-helper"
                    placeholder="Email"
                    fullWidth
                    autoFocus={true}
                    size="small"
                    margin="dense"
                    value={email}
                    error={validations.email ? true : false}
                    helperText={validations.email || " "}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <LoadingButton
                    variant="contained"
                    loading={sendMailLoader}
                    fullWidth
                    size="large"
                    sx={{ my: 5, py: "8px" }}
                    onClick={() => handleSendEmail()}
                  >
                    Send Email
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={displayAlert}
        onClose={() => {
          closeAlertMessage();
        }}
        key={"system-message"}
        autoHideDuration={5000}
      >
        <Alert severity={alertVariant}>{alertMessage}</Alert>
      </Snackbar>
    </Box>
  );
}
