import './App.css';
import MainLayout from "./components/MainLayout"
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1497D4',
    },
    secondary: {
      main: '#ff8e5e'
    }
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <MainLayout />
      </ThemeProvider>
    </div>
  );
}

export default App;
