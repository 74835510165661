import { useState, useEffect } from "react";
import {
  Box,
  Paper,
  TextField,
  Grid,
  Button,
  Switch,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TablePagination,
  TableFooter,
  Snackbar,
  Alert,
  Typography,
  Tooltip,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import axios from "../../config/axios";
import { Close, Delete, Edit } from "@mui/icons-material";
import { isAdmin, isSuperAdmin, getUserData } from "../../utils/utilities";
import AddAdminModal from "./addAdminModal";
import EditModal from "./editUserModal";

let timerId;

const initAlert = { status: false, msg: "", variant: "" };

const UserList = () => {
  const [userList, setUserList] = useState([]);
  // search data
  const [search, setSearch] = useState("");
  // pagination data
  const [totalRows, setTotalRows] = useState(5);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  // alert data
  const [alert, setAlert] = useState(initAlert);
  // modals
  const [addAdminModal, setAddAdminModal] = useState(false);
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] =
    useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [userData, setUserData] = useState({});
  // misc
  const [deleteProfile, setDeleteProfile] = useState({});

  // other functions
  const debounceSearch = (search, rowsPerPage, page) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      if (isSuperAdmin()) {
        getAdminList(search, rowsPerPage, page);
      }
      timerId = null;
    }, 400);
  };

  const closeAlertMessage = () => {
    setAlert(initAlert);
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
  };

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmationDialog(false);
  };

  const getList = () => {
    getAdminList(search, rowsPerPage, page);
  };

  // API Services
  const getUserList = (search, rowsPerPage, page) => {
    const query = {
      search,
      rowsPerPage,
      page: page + 1,
    };

    const body = { adminId: getUserData().id };

    axios
      .post("/user/user-list", body, { params: query })
      .then((res) => {
        if (res.status === 200) {
          setTotalRows(res.data.data?.total || 0);
          setUserList(res.data.data?.list || []);
        }
      })
      .catch((err) => console.log(err));
  };

  const getAdminList = (search, rowsPerPage, page) => {
    const query = {
      search,
      rowsPerPage,
      page: page + 1,
    };

    axios
      .get("/admin/admin-list", { params: query })
      .then((res) => {
        if (res.status === 200) {
          setTotalRows(res.data.data?.total || 0);
          setUserList(res.data.data?.list || []);
        }
      })
      .catch((err) => console.log(err));
  };

  const toggleUserStatus = (userId) => {
    let url, getFun, body;

    if (isSuperAdmin()) {
      url = "admin/toggle-status";
      getFun = getAdminList;
      body = { adminId: userId };
    } else if (isAdmin()) {
      url = "user/toggle-status";
      getFun = getUserList;
      body = { userId };
    }

    axios
      .patch(url, body)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status) {
            getFun(search, rowsPerPage, page);
            setAlert({
              status: true,
              msg: res.data.message,
              variant: "success",
            });
          } else {
            if (res.data.type === "error") {
              setAlert({
                status: true,
                msg: res.data.error?.message || "",
                variant: "error",
              });
            }
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteAdmin = (id) => {
    axios
      .delete(`admin/delete?id=${id}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status) {
            setAlert({
              status: true,
              msg: res.data.message,
              variant: "success",
            });
            setDeleteProfile({});
            getList();
          } else {
            if (res.data.type === "error") {
              setAlert({
                status: true,
                msg: res.data.error?.message || "",
                variant: "error",
              });
            }
          }
        }
      })
      .catch((err) => console.log({ err }));
  };

  useEffect(() => {
    if (isSuperAdmin()) {
      getList(search, rowsPerPage, page);
    } else if (isAdmin()) {
      getUserList(search, rowsPerPage, page);
    }
  }, [rowsPerPage, page]);

  return (
    <div>
      <Grid container rowSpacing={2}>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} item sx={{ pb: 2 }}>
          <Typography variant="body1" sx={{ fontSize: "20px" }} color="#474747">
            User Management
          </Typography>
        </Grid>
        <Paper sx={{ width: "100%" }} elevation={0}>
          <Box p={3}>
            <Grid container sx={{ backgroundColor: "white" }}></Grid>
            <Grid container sx={{ backgroundColor: "white" }} rowSpacing={2}>
              <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                <TextField
                  autoFocus
                  size="small"
                  placeholder="Search"
                  value={search || ""}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    debounceSearch(e.target.value, rowsPerPage, page);
                  }}
                />
              </Grid>
              {isSuperAdmin() || isAdmin() ? (
                <Grid
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  item
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <Button
                    variant="contained"
                    onClick={() => setAddAdminModal(true)}
                  >
                    {isSuperAdmin() ? "Add New User" : "Add New User"}
                  </Button>
                </Grid>
              ) : null}
              <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                <TableContainer component={Paper} elevation={0}>
                  <Table
                    aria-label="simple table"
                    className={"custom-table br-0"}
                  >
                    <TableHead sx={{ backgroundColor: "#f4f6f9" }}>
                      <TableRow>
                        <TableCell
                          sx={{
                            width: "80px",
                            minWidth: "80px",
                            textAlign: "center",
                          }}
                        >
                          No.
                        </TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell></TableCell>
                        <TableCell sx={{ width: "80px", minWidth: "80px" }}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!userList.length ? (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <Typography
                              sx={{ width: "100%", textAlign: "center" }}
                            >
                              No Data Available
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ) : (
                        userList.map((user, ind) => (
                          <TableRow>
                            <TableCell sx={{ textAlign: "center" }}>
                              {ind + 1}
                            </TableCell>
                            <TableCell>{user.name}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>
                              {user.isSuper
                                ? "Super Admin"
                                : user.isAdmin
                                ? "Employee"
                                : ""}
                            </TableCell>

                            <TableCell sx={{ width: "100px" }}>
                              <Switch
                                color="primary"
                                checked={!user.is_disabled}
                                onChange={() => toggleUserStatus(user._id)}
                              />
                            </TableCell>
                            <TableCell>
                              <Box style={{ display: "flex" }}>
                                <Tooltip title="Delete">
                                  <IconButton
                                    color="inherit"
                                    sx={{ padding: 0, marginLeft: "5px" }}
                                    onClick={() => {
                                      setDeleteConfirmationDialog(true);
                                      setDeleteProfile(user);
                                    }}
                                  >
                                    <Delete fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Edit">
                                  <IconButton
                                    color="inherit"
                                    sx={{ padding: 0, marginLeft: "5px" }}
                                    onClick={() => {
                                      setEditUserModal(true);
                                      setUserData(user);
                                    }}
                                  >
                                    <Edit fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[
                            5,
                            10,
                            25,
                            { label: "All", value: -1 },
                          ]}
                          count={totalRows}
                          colSpan={6}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={alert.status}
        onClose={closeAlertMessage}
        message={alert.msg}
        key={"system-message"}
        autoHideDuration={2000}
      >
        <Alert severity={alert.variant}>
          {alert.msg}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            sx={{ ml: 2 }}
            onClick={closeAlertMessage}
          >
            <Close fontSize="small" />
          </IconButton>
        </Alert>
      </Snackbar>
      {addAdminModal ? (
        <AddAdminModal
          open={addAdminModal}
          setOpen={setAddAdminModal}
          setAlert={setAlert}
          getList={getList}
        />
      ) : null}
      <Dialog open={deleteConfirmationDialog}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {deleteProfile.name}'s profile?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDeleteConfirmationClose()}>Close</Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              handleDeleteConfirmationClose();
              handleDeleteAdmin(deleteProfile._id);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {editUserModal ? (
        <EditModal
          data={userData}
          setData={setUserData}
          open={editUserModal}
          setOpen={setEditUserModal}
          alert={alert}
          setAlert={setAlert}
          getList={getList}
        />
      ) : null}
    </div>
  );
};

export default UserList;
