import { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import FormLabel from "../../components/FormLabel";
import { resolveErrMsg, getUserData } from "../../utils/utilities";
import axios from "../../config/axios";

const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  outline: "none",
  p: 4,
};

const UserDetails = (props) => {
  const [validations, setValidations] = useState({});
  const [btnLoader, setBtnLoader] = useState(false);

  // other functions
  const handleChange = (field, value) =>
    props.setData((prevState) => ({
      ...prevState,
      [field]: value,
    }));

  const handleCloseModal = () => {
    props.setOpen(false);
  };

  // API Services
  const updateUserDetails = () => {
    setBtnLoader(true);
    const params = {
      id: props.data._id,
    };

    const body = {
      email: props.data.email,
      name: props.data.name,
      isSuper: props.data.isSuper,
    };

    axios
      .put("user/update", body, { params })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status) {
            props.setAlert({
              variant: "success",
              msg: res.data.message,
              status: true,
            });
            props.setData({});
            handleCloseModal();
            props.getList();
          } else {
            if (res.data.type === "validation") {
              const tempVal = {};
              res.data.error?.forEach((err) => {
                tempVal[err.field] = resolveErrMsg(err.type);
              });
              setValidations(tempVal);
            }
            if (res.data.type === "error") {
              props.setAlert({
                variant: "error",
                msg: res.data.error?.message,
                status: true,
              });
            }
          }
          setBtnLoader(false);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    return () => {
      props.setData({});
    };
  }, []);

  return (
    <Modal open={props.open}>
      <Box style={style}>
        <Grid container sx={{ justifyContent: "space-around" }}>
          <Grid
            item
            xs={8}
            sm={6}
            md={6}
            xl={4}
            lg={4}
            sx={{ display: "flex" }}
          >
            <Paper style={{ ...props.style, width: "100%" }} elevation={0}>
              <Box sx={{ paddingLeft: "10px" }}>
                <Grid container>
                  <Grid xs={11} sm={11} md={11} lg={11} xl={11} item>
                    <Box style={{ paddingBottom: "10px", marginTop: "15px" }}>
                      <Typography variant="h5">Edit Profile</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    xs={1}
                    sm={1}
                    md={1}
                    lg={1}
                    xl={1}
                    item
                    sx={{ textAlign: "right" }}
                  >
                    <IconButton onClick={handleCloseModal}>
                      <Close color="error" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
              <Box style={{ padding: "10px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormLabel title="Name" />
                    <TextField
                      fullWidth
                      placeholder="Name"
                      size="small"
                      error={validations.name}
                      helperText={validations.name}
                      value={props.data.name || ""}
                      onChange={(e) => handleChange("name", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormLabel title="Email" />
                    <TextField
                      fullWidth
                      placeholder="Email"
                      size="small"
                      error={validations.email}
                      helperText={validations.email}
                      value={props.data.email || ""}
                      onChange={(e) => handleChange("email", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Select
                      placeholder="Role"
                      size="small"
                      value={props.data.isSuper || false}
                      onChange={(e) => handleChange("isSuper", e.target.value)}
                      fullWidth
                    >
                      <MenuItem value={false}> Employee </MenuItem>
                      <MenuItem value={true}>Super Admin</MenuItem>
                    </Select>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "20px",
                    }}
                  >
                    <LoadingButton
                      loading={btnLoader}
                      variant="contained"
                      onClick={updateUserDetails}
                    >
                      Update
                    </LoadingButton>
                    <Button
                      color="error"
                      variant="contained"
                      sx={{ marginLeft: "10px" }}
                      onClick={handleCloseModal}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default UserDetails;
