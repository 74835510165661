import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Alert,
  Grid,
  TextField,
  IconButton,
  OutlinedInput,
  InputAdornment,
  FormControl,
  FormHelperText,
  Snackbar,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Visibility, VisibilityOff, Close } from "@mui/icons-material";
import axios from "../../config/axios";
import { resolveErrMsg, getUserData } from "../../utils/utilities";
import { useAuth } from "../../hooks/auth";

const ChangePassword = () => {
  const [data, setData] = useState({});
  const [validations, setValidations] = useState({});
  const [showPassword, setShowPassword] = useState({});
  const [btnLoader, setBtnLoader] = useState(false);
  const [alert, setAlert] = useState({});
  const auth = useAuth();

  // other functions
  const closeAlertMessage = () => {
    setAlert({});
  };

  const handleChange = (field, value) => {
    handleValidationChange("field", "");
    setData((prevState) => ({
      ...prevState,
      [`${field}`]: value,
    }));
  };

  const togglePasswordVisibility = (field) =>
    setShowPassword((prevState) => ({
      ...prevState,
      [`${field}`]: !prevState[field],
    }));

  const handleValidationChange = (field, value) =>
    setValidations((prevState) => ({
      ...prevState,
      [`${field}`]: value,
    }));

  const handleLogout = () => {
    auth.logout();
  };

  const handlePasswordChangeValidations = () => {
    let flag = true;

    if (data.newPassword?.length < 8 && flag) {
      handleValidationChange(
        "newPassword",
        "password must be minimum 8 characters long!"
      );
      flag = false;
    }

    if (data.newPassword?.length > 30 && flag) {
      handleValidationChange(
        "newPassword",
        "password cannot be more that 30 characters long!"
      );
      flag = false;
    }

    if (data.newPassword !== data.confirmNewPwd && flag) {
      handleValidationChange("confirmNewPwd", "Password doesn't match!");
      flag = false;
    }

    return flag;
  };

  // API Service
  const handleChangePassword = () => {
    setValidations({});
    setBtnLoader(true);

    if (!handlePasswordChangeValidations()) {
      setBtnLoader(false);
      return;
    }

    const body = {
      newPassword: data.newPassword,
      oldPassword: data.oldPassword,
      id: getUserData().id,
    };

    axios
      .put("/auth/change-password", body)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status) {
            setAlert({
              variant: "success",
              msg: res.data.message,
              status: true,
            });
            setTimeout(() => {
              handleLogout();
            }, 2000);
          } else {
            if (res.data.type === "validation") {
              const tempVal = {};
              res.data.error?.forEach((err) => {
                tempVal[err.field] = resolveErrMsg(err.type);
              });
              // manually set validation for password confirmation
              if (!data.confirmNewPwd) {
                tempVal.confirmNewPwd = "Required!";
              }
              setValidations(tempVal);
            }
            if (res.data.type === "error") {
              setAlert({
                variant: "error",
                msg: res.data.error?.message,
                status: true,
              });
            }
          }
        }
        setBtnLoader(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box
      style={{
        width: "100%",
        height: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper elevation={0} style={{ padding: "25px", maxWidth: "600px" }}>
        <Grid container rowSpacing={3}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            xl={12}
            lg={12}
            sx={{ textAlign: "center" }}
          >
            <Typography variant="h6">Change Password</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <FormControl fullWidth error={validations.oldPassword}>
              <OutlinedInput
                id="demo-helper-text-misaligned-no-helper-2"
                type={showPassword.oldPassword ? "text" : "password"}
                placeholder={"Old Password"}
                size="small"
                margin="dense"
                value={data.oldPassword || ""}
                onChange={(e) => handleChange("oldPassword", e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => togglePasswordVisibility("oldPassword")}
                      edge="end"
                    >
                      {showPassword.oldPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText sx={{ marginLeft: 0 }}>
                {validations.oldPassword || ""}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <FormControl fullWidth error={validations.newPassword}>
              <OutlinedInput
                id="demo-helper-text-misaligned-no-helper-2"
                type={showPassword.newPassword ? "text" : "password"}
                placeholder={"New Password"}
                size="small"
                margin="dense"
                value={data.newPassword || ""}
                onChange={(e) => handleChange("newPassword", e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => togglePasswordVisibility("newPassword")}
                      edge="end"
                    >
                      {showPassword.newPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText sx={{ marginLeft: 0 }}>
                {validations.newPassword || ""}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <FormControl fullWidth error={validations.confirmNewPwd}>
              <OutlinedInput
                id="demo-helper-text-misaligned-no-helper-2"
                type={showPassword.confirmNewPwd ? "text" : "password"}
                placeholder={"Confirm New Password"}
                size="small"
                margin="dense"
                value={data.confirmNewPwd || ""}
                onChange={(e) => handleChange("confirmNewPwd", e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => togglePasswordVisibility("confirmNewPwd")}
                      edge="end"
                    >
                      {showPassword.confirmNewPwd ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText sx={{ marginLeft: 0 }}>
                {validations.confirmNewPwd || ""}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <LoadingButton
              variant="contained"
              loading={btnLoader}
              fullWidth
              size="large"
              sx={{ my: 5, py: "8px" }}
              onClick={() => handleChangePassword()}
            >
              Change Password
            </LoadingButton>
          </Grid>
        </Grid>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={alert.status}
        onClose={closeAlertMessage}
        message={alert.msg}
        key={"system-message"}
        autoHideDuration={2000}
      >
        <Alert severity={alert.variant}>
          {alert.msg}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            sx={{ ml: 2 }}
            onClick={closeAlertMessage}
          >
            <Close fontSize="small" />
          </IconButton>
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ChangePassword;
