import { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Stack,
  Paper,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import axios from "../../../config/axios.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "100%",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  outline: "none",
  p: 4,
};

const AddNRModal = (props) => {
  const [nationality, setNationality] = useState("");
  const [religion, setReligion] = useState("");
  const [validations, setValidations] = useState({});
  const [alertMsg, setAlertMsg] = useState({});

  // API Services //
  const addReligion = () => {
    const body = {
      name: religion,
    };

    axios
      .post("/admin/religions", body)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status) {
            setAlertMsg({ status: true, msg: "Religion added successfully!" });
            setReligion("");
            props.fetchReligionOptions();
          } else {
            if (res.data.type === "validation" && res.data.error) {
              const tempValidation = {};
              res.data.error.forEach((val) => {
                tempValidation.religion = { status: true, msg: val.message };
              });
              setValidations((prevState) => ({
                ...prevState,
                ...tempValidation,
              }));
            }
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const addNationality = () => {
    const body = {
      name: nationality,
    };

    axios
      .post("/admin/nationalities", body)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status) {
            setAlertMsg({
              status: true,
              msg: "Nationality added successfully!",
            });
            setNationality("");
            props.fetchNationalityOptions();
          } else {
            if (res.data.type === "validation" && res.data.error) {
              const tempValidation = {};
              res.data.error.forEach((val) => {
                tempValidation.nationality = { status: true, msg: val.message };
              });
              setValidations((prevState) => ({
                ...prevState,
                ...tempValidation,
              }));
            }
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal open={props.open.addNR}>
      <Box style={style}>
        <Grid container sx={{ justifyContent: "space-around" }}>
          <Grid xs={8} sm={6} md={5} xl={4} lg={3} item>
            <Paper style={{ ...props.style, width: "100%" }} elevation={0}>
              <Box sx={{ padding: "10px" }}>
                <Grid container>
                  <Grid xs={11} sm={11} md={11} lg={11} xl={11} item>
                    <Box style={{ paddingBottom: "10px" }}>
                      <Typography variant="h5">
                        Add Nationality/Religion
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    xs={1}
                    sm={1}
                    md={1}
                    lg={1}
                    xl={1}
                    item
                    sx={{ textAlign: "right" }}
                  >
                    <IconButton
                      style={{ paddingTop: "0px", paddingRight: "0px" }}
                      onClick={() =>
                        props.setOpen({ ...props.open, addNR: false })
                      }
                    >
                      <Close color="error" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Stack spacing={1}>
                  <Grid container spacing={1}>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                      <Grid container spacing={1}>
                        <Grid xs={9} sm={9} md={9} lg={9} xl={9} item>
                          <TextField
                            size="small"
                            placeholder={"Nationality"}
                            error={validations.nationality?.status}
                            value={nationality}
                            helperText={validations.nationality?.msg}
                            onChange={(e) => {
                              if (validations.nationality?.status) {
                                setValidations((prevState) => ({
                                  ...prevState,
                                  nationality: { status: false, msg: "" },
                                }));
                              }
                              setNationality(e.target.value);
                            }}
                            style={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid xs={3} sm={3} md={3} lg={3} xl={3} item>
                          <Button variant="contained" onClick={addNationality}>
                            Add
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                      <Grid spacing={1} container>
                        <Grid xs={9} sm={9} md={9} lg={9} xl={9} item>
                          <TextField
                            size="small"
                            placeholder={"Religion"}
                            error={validations.religion?.status}
                            value={religion}
                            helperText={validations.religion?.msg}
                            onChange={(e) => {
                              if (validations.religion?.status) {
                                setValidations((prevState) => ({
                                  ...prevState,
                                  religion: { status: false, msg: "" },
                                }));
                              }
                              setReligion(e.target.value);
                            }}
                            style={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid xs={3} sm={3} md={3} lg={3} xl={3} item>
                          <Button variant="contained" onClick={addReligion}>
                            Add
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Stack>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Snackbar
          open={alertMsg.status}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={2000}
          onClose={() => setAlertMsg({})}
        >
          <Alert severity={alertMsg.severity} sx={{ width: "100%" }}>
            {alertMsg.msg}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
};

export default AddNRModal;
