import { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
  Paper,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import axios from "../../../config/axios";
import { LoadingButton } from "@mui/lab";
import FormLabel from "../../../components/FormLabel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "100%",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  outline: "none",
  p: 4,
};

const ManageLinkedProfile = (props) => {
  const [btnLoader, setBtnLoader] = useState(false);
  const [adminList, setAdminList] = useState([]);
  const [userList, setUserList] = useState([]);

  // other functions
  const handleCloseModal = () => {
    props.setOpen(false);
  };

  const handleToggle = (list, setList, id) => {
    const tempList = [...list];
    if (tempList.includes(id)) {
      const index = tempList.indexOf(id);
      tempList.splice(index, 1);
    } else {
      tempList.push(id);
    }
    setList(tempList);
  };

  const handleUpdate = () => {
    setBtnLoader(true);
    const params = {
      profileId: props.data._id,
    };

    const body = {
      userList,
      adminList,
    };

    axios
      .put("employee-profile/update-ownership", body, { params })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status) {
            props.setAlertVariant("success");
            props.setAlertMessage(res.data.message);
            props.setAlert(true);
            handleCloseModal();
            props.getList();
          } else {
            if (res.data.type === "error") {
              props.setAlertVariant("error");
              props.setAlertMessage(res.data.error?.message);
              props.setAlert(true);
            }
          }
        }
        setBtnLoader(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (props.data) {
      setAdminList(props.data.assignedToAdmins?.map((adm) => adm._id) || []);
      setUserList(props.data.assignedToUsers?.map((usr) => usr._id) || []);
    }
  }, []);

  return (
    <Modal open={props.open}>
      <Box style={style}>
        <Grid container sx={{ justifyContent: "space-around" }}>
          <Grid
            item
            xs={8}
            sm={6}
            md={6}
            xl={4}
            lg={4}
            sx={{ display: "flex" }}
          >
            <Paper style={{ ...props.style, width: "100%" }} elevation={0}>
              <Box sx={{ paddingLeft: "10px" }}>
                <Grid container>
                  <Grid xs={11} sm={11} md={11} lg={11} xl={11} item>
                    <Box style={{ paddingBottom: "10px", marginTop: "15px" }}>
                      <Typography variant="h5">
                        Manage Linked Profile
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    xs={1}
                    sm={1}
                    md={1}
                    lg={1}
                    xl={1}
                    item
                    sx={{ textAlign: "right" }}
                  >
                    <IconButton onClick={handleCloseModal}>
                      <Close color="error" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
              <Box style={{ padding: "10px" }}>
                <Grid container spacing={1}>
                  {props.data?.assignedToAdmins?.length ? (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormLabel title="Admins" />
                      <FormGroup row>
                        {props.data?.assignedToAdmins?.map((admin) => (
                          <FormControlLabel
                            control={<Checkbox />}
                            label={admin.name}
                            checked={adminList.includes(admin._id)}
                            onClick={() =>
                              handleToggle(adminList, setAdminList, admin._id)
                            }
                          />
                        ))}
                      </FormGroup>
                    </Grid>
                  ) : null}
                  {props.data?.assignedToUsers?.length ? (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormLabel title="Users" />
                      <FormGroup style={{ display: "flex" }}>
                        {props.data?.assignedToUsers?.map((user) => (
                          <FormControlLabel
                            control={<Checkbox />}
                            label={user.name}
                            checked={userList.includes(user._id)}
                            onClick={() =>
                              handleToggle(userList, setUserList, user._id)
                            }
                          />
                        ))}
                      </FormGroup>
                    </Grid>
                  ) : null}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "20px",
                    }}
                  >
                    <LoadingButton
                      loading={btnLoader}
                      variant="contained"
                      onClick={handleUpdate}
                    >
                      Update
                    </LoadingButton>
                    <Button
                      color="error"
                      variant="contained"
                      sx={{ marginLeft: "10px" }}
                      onClick={handleCloseModal}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ManageLinkedProfile;
