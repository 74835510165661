import { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Stack,
  Paper,
  IconButton,
  Autocomplete,
  Collapse,
  Snackbar,
  Alert,
  Slide,
  Chip,
} from "@mui/material";
import { Close, AddCircleOutline } from "@mui/icons-material";
import axios from "../../../config/axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "100%",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  outline: "none",
  p: 4,
};

const AddToolModal = (props) => {
  // page states
  const [toolName, setToolName] = useState([]);
  const [name, setName] = useState("");
  const [successMsg, setSuccessMsg] = useState(false);

  const [newTools, setNewTools] = useState(false);
  const [validation, setValidation] = useState({
    tool: { status: false, msg: "" },
  });

  // other functions
  const handleSubmit = () => {
    if (isFormValid(toolName)) {
      props.setOpen({ ...props.open, addTool: false });
      const tempData = props.employeeData?.tools;
      const tools = toolName.map((tool) => ({
        name: tool.name,
        value: 75,
      }));
      tempData.push(...tools);
      props.setEmployeeData({ ...props.employeeData, tools: [...tempData] });
      setToolName([]);
    }
  };

  const isFormValid = (toolName) => {
    if (!toolName || !toolName.length) {
      setValidation(() => ({
        ...validation,
        tool: { status: true, msg: "Required" },
      }));
      return false;
    }
    return true;
  };

  const addTools = async () => {
    try {
      const response = await axios.post("/admin/tools", { name: name });

      if (response.data.status) {
        setName("");
        setNewTools(false);
        setSuccessMsg(true);
        props.fetchToolsList();
      } else {
        if (response.data.type === "validation") {
          setValidation(() => ({
            ...validation,
            tool: { status: true, msg: "Required" },
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal open={props.open.addTool}>
        <Box style={style}>
          <Grid container sx={{ justifyContent: "space-around" }}>
            <Grid xs={8} sm={6} md={5} xl={4} lg={3} item>
              <Paper style={{ ...props.style, width: "100%" }} elevation={0}>
                <Box sx={{ padding: "10px" }}>
                  <Grid container>
                    <Grid xs={11} sm={11} md={11} lg={11} xl={11} item>
                      <Box style={{ paddingBottom: "10px" }}>
                        <Typography variant="h5">Add Tool</Typography>
                      </Box>
                    </Grid>
                    <Grid
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      xl={1}
                      item
                      sx={{ textAlign: "right" }}
                    >
                      <IconButton
                        style={{ paddingTop: "0px", paddingRight: "0px" }}
                        onClick={() =>
                          props.setOpen({ ...props.open, addTool: false })
                        }
                      >
                        <Close color="error" />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Stack spacing={1}>
                    <Grid container alignItems={"center"}>
                      <Grid xs={11} sm={11} md={11} lg={11} xl={11} item>
                        <Autocomplete
                          id="size-small-outlined"
                          size="small"
                          options={props.toolsList}
                          multiple
                          getOptionLabel={(option) => option.name}
                          renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                              <Chip
                                sx={{ fontWeight: "500" }}
                                label={option.name}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tools & tach"
                              placeholder="Favorites"
                            />
                          )}
                          onChange={(_, value) => setToolName(value)}
                        />
                      </Grid>
                      <Grid
                        xs={1}
                        sm={1}
                        md={1}
                        lg={1}
                        xl={1}
                        item
                        textAlign={"end"}
                      >
                        <IconButton
                          size="small"
                          onClick={() => {
                            setNewTools((pre) => !pre);
                            setValidation({ tool: { status: false, msg: "" } });
                          }}
                          sx={{ paddingTop: "0px" }}
                        >
                          <AddCircleOutline color="primary" />
                        </IconButton>
                      </Grid>
                      <Grid xs={11} sm={11} md={11} lg={11} xl={11} item mt={3}>
                        <Collapse in={newTools}>
                          <TextField
                            placeholder="New tools name"
                            size="small"
                            sx={{ width: "100%" }}
                            error={validation.tool.status}
                            helperText={validation.tool.msg}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Collapse>
                      </Grid>
                    </Grid>
                  </Stack>
                  <Box sx={{ paddingTop: "40px" }}>
                    {newTools ? (
                      <Button variant="contained" onClick={(e) => addTools()}>
                        Add Tools
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={(e) => handleSubmit()}
                      >
                        Submit
                      </Button>
                    )}
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={successMsg}
        TransitionComponent={Slide}
        autoHideDuration={2000}
        onClose={() => {
          setSuccessMsg(false);
        }}
      >
        <Alert serverity="success">Tools Add Successfully!</Alert>
      </Snackbar>
    </>
  );
};

export default AddToolModal;
