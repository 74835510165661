import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import TableSortLabel from "@mui/material/TableSortLabel";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TableHead from "@mui/material/TableHead";
import { visuallyHidden } from "@mui/utils";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import API from "../../config/axios";
import Switch from "@mui/material/Switch";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogContentText from "@mui/material/DialogContentText";
import { isSuperAdmin } from "../../utils/utilities";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function CustomPaginationActionsTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [addDialog, setAddDialog] = React.useState(false);
  const [editDialog, setEditDialog] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [editLanguageId, setEditLanguageId] = React.useState(null);
  const [deleteLanguageId, setDeleteLanguageId] = React.useState(null);
  const [name, setName] = React.useState("");
  const [displayAlert, setDisplayAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertVariant, setAlertVariant] = React.useState("success");
  const [inputErrors, setInputErrors] = React.useState({});

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = rows.length === 0 ? 1 : 0;

  const displayAlertMessage = (variant, message) => {
    setDisplayAlert(true);
    setAlertMessage(message);
    setAlertVariant(variant);
  };

  const closeAlertMessage = () => {
    setDisplayAlert(false);
    setAlertMessage("");
  };

  const getLanguages = () => {
    API.get("admin/languages", {
      params: {
        pagination: true,
        page: page + 1,
        rowsPerPage: rowsPerPage,
        sortBy: orderBy,
        descending: order === "asc" ? false : true,
        search: search,
      },
    })
      .then((res) => {
        const data = res.data;

        if (data.status) {
          setRows(data.data.results);
          setTotalRows(data.data.totalResults || 0);
          setPage(
            data.data.page > data.data.totalPages ? 0 : data.data.page - 1
          );
        } else {
          console.log("something went wrong");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const addLanguage = () => {
    API.post("admin/languages", {
      name: name,
    }).then((res) => {
      const data = res.data;

      if (data.status) {
        displayAlertMessage("success", "Language Added Successfully");
        handleAddDialogClose();
      } else {
        if (data && data.type === "validation" && data.error.length > 0) {
          data.error.forEach((error) => {
            setInputErrors((prevState) => {
              return { [error.field]: error.message, ...prevState };
            });
          });
        } else {
          displayAlertMessage("error", "Something went wrong");
          handleAddDialogClose();
        }
      }

      getLanguages();
    });
  };

  const disableLanguage = (id) => {
    API.put(`admin/languages/${id}/toggle_status`)
      .then((res) => {
        const data = res.data;

        if (data.status) {
          displayAlertMessage("success", data.message);
          getLanguages();
        } else {
          displayAlertMessage("error", "Something went wrong");
        }
      })
      .catch(() => {
        displayAlertMessage("error", "Something went wrong");
      });
  };

  const editLanguage = () => {
    API.put(`admin/languages/${editLanguageId}`, {
      name: name,
    })
      .then((res) => {
        const data = res.data;

        if (data.status) {
          displayAlertMessage("success", "Language Updated Successfully");
          handleEditDialogClose();
          getLanguages();
        } else {
          if (data && data.type === "validation" && data.error.length > 0) {
            data.error.forEach((error) => {
              setInputErrors((prevState) => {
                return { [error.field]: error.message, ...prevState };
              });
            });
          } else {
            displayAlertMessage("error", "Something went wrong");
            handleEditDialogClose();
          }
        }
      })
      .catch(() => {
        displayAlertMessage("error", "Something went wrong");
      });
  };

  const deleteLanguage = () => {
    API.delete(`admin/languages/${deleteLanguageId}`)
      .then((res) => {
        const data = res.data;

        if (data.status) {
          displayAlertMessage("success", "Language Deleted Successfully");
          handleDeleteDialogClose();
          getLanguages();
        } else {
          displayAlertMessage("error", "Something went wrong");
        }
      })
      .catch(() => {
        displayAlertMessage("error", "Something went wrong");
      });
  };

  React.useEffect(() => {
    getLanguages();
  }, [page, order, orderBy, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddDialogClose = () => {
    setAddDialog(false);
    setInputErrors({});
    setName("");
  };

  const handleEditDialogClose = () => {
    setEditDialog(false);
    setEditLanguageId(null);
    setInputErrors({});
    setName("");
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialog(false);
    setDeleteLanguageId(null);
  };

  const showEditDialog = (data) => {
    setName(data.name);
    setEditLanguageId(data.id);
    setEditDialog(true);
  };

  const showDeleteConfirmationDialog = (id) => {
    setDeleteLanguageId(id);
    setDeleteDialog(true);
  };

  const searchData = () => {
    getLanguages();
  };

  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Language",
      sortable: true,
    },
    {
      id: "action",
      numeric: false,
      disablePadding: true,
      label: "Actions",
      sortable: false,
    },
  ];

  return (
    <div>
      <Grid container rowSpacing={0}>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <Typography variant="body1" sx={{ fontSize: "20px" }} color="#474747">
            Language Management
          </Typography>
        </Grid>
        <Grid item xs={12} px={"25px"} sx={{ backgroundColor: "white", py: 3 }}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems="stretch"
            spacing={2}
            sx={{ mb: 3 }}
          >
            <Box sx={{ display: "flex", alignItems: "stretch", gap: 2 }}>
              <TextField
                id="outlined-search"
                size="small"
                placeholder="Search"
                type="search"
                className="custom-input"
                sx={{
                  width: isMobile ? 2 / 3 : "100%",
                }}
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: 0,
                  textTransform: "capitalize",
                  width: isMobile ? 1 / 3 : "120px",
                }}
                onClick={searchData}
                disableRipple
              >
                Search
              </Button>
            </Box>
            <Button
              variant="contained"
              color="primary"
              sx={{ borderRadius: 0, textTransform: "capitalize" }}
              onClick={() => setAddDialog(true)}
              disableRipple
            >
              Add Language
            </Button>
          </Stack>

          <TableContainer component={Paper} elevation={0}>
            <Table
              aria-label="custom pagination table"
              className={"custom-table br-0"}
            >
              <TableHead sx={{ backgroundColor: "#f4f6f9" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      width: "80px",
                      minWidth: "80px",
                      textAlign: "center",
                    }}
                  >
                    No.
                  </TableCell>
                  {headCells
                    .filter((h) => (isSuperAdmin() ? {} : h.id !== "action"))
                    .map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        {headCell.sortable ? (
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                          >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          headCell.label
                        )}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell style={{ textAlign: "center" }}>
                      {page * rowsPerPage + (index + 1)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    {isSuperAdmin() && (
                      <TableCell style={{ width: 160 }} align="left">
                        <Switch
                          checked={!row.is_disabled ? true : false}
                          onChange={() => {
                            disableLanguage(row.id);
                          }}
                          inputProps={{ "aria-label": "" }}
                          color="primary"
                        />
                        <IconButton
                          size="small"
                          aria-label="edit"
                          color="inherit"
                          onClick={() => showEditDialog(row)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          aria-label="edit"
                          color="inherit"
                          onClick={() => showDeleteConfirmationDialog(row.id)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6}>
                      <Typography sx={{ width: "100%", textAlign: "center" }}>
                        No Data Available
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      1,
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Dialog open={addDialog} maxWidth={"xs"} fullWidth>
        <div>
          <IconButton
            sx={{ position: "absolute", right: 10, top: 5 }}
            onClick={handleAddDialogClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 2,
          }}
        >
          Add Language
        </DialogTitle>
        <DialogContent>
          <TextField
            id="name"
            placeholder="Name"
            value={name}
            autoFocus
            size="small"
            onChange={(e) => setName(e.target.value)}
            fullWidth
            error={inputErrors.name ? true : false}
            helperText={inputErrors.name || " "}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ m: 2, p: 1 }}
            disableRipple
            onClick={addLanguage}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editDialog} maxWidth={"xs"} fullWidth>
        <div>
          <IconButton
            sx={{ position: "absolute", right: 10, top: 5 }}
            onClick={handleEditDialogClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 2,
          }}
        >
          Edit Language
        </DialogTitle>
        <DialogContent>
          <TextField
            id="name"
            placeholder="Name"
            value={name}
            autoFocus
            size="small"
            onChange={(e) => setName(e.target.value)}
            fullWidth
            error={inputErrors.name ? true : false}
            helperText={inputErrors.name || " "}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ m: 2, p: 1 }}
            disableRipple
            onClick={editLanguage}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteDialog} maxWidth={"xs"}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to Delete this Language?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>Close</Button>
          <Button
            onClick={() => {
              deleteLanguage();
            }}
            autoFocus
            variant="contained"
            color="error"
          >
            delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={displayAlert}
        onClose={closeAlertMessage}
        message={displayAlert}
        key={"system-message"}
        autoHideDuration={2000}
      >
        <Alert severity={alertVariant}>
          {alertMessage}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            sx={{ ml: 2 }}
            onClick={closeAlertMessage}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Alert>
      </Snackbar>
    </div>
  );
}
