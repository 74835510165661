import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Paper,
  Stack,
  TextField,
  Grid,
  Button,
  IconButton,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TablePagination,
  TableFooter,
  Tooltip,
  Snackbar,
  Alert,
  Typography,
  useMediaQuery,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Modal,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Edit, Visibility, Delete, ContentCopy } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import {
  LastPage,
  FirstPage,
  KeyboardArrowRight,
  KeyboardArrowLeft,
  Close,
  AddLink,
  Link,
} from "@mui/icons-material";
import API from "../../config/axios";
import ProfilePreviewDialog from "./profilePreviewDialog";
import { isSuperAdmin, isAdmin, getUserData } from "../../utils/utilities.js";
import LinkProfileModal from "./PageModals/linkProfileModal";
import ManageLinkedProfileModal from "./PageModals/manageLinkedProfileModal";

let timerId = null;

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const List = () => {
  const navigate = useNavigate();
  const [totalRows, setTotalRows] = useState(5);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState(null);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("success");
  const [copyingProfile, setCopyingProfileLoader] = useState({});
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] =
    useState(false);
  const [deleteProfile, setDeleteProfile] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [generatingPreview, setGeneratingPreview] = useState(false);
  const [previewSourcePath, setPreviewSourcePath] = useState("");
  const [showProfilePreviewDialog, setProfilePreviewDialog] = useState(false);
  const [previewLoader, setPreviewLoader] = useState(false);
  const [linkProfileModal, setLinkProfileModal] = useState(false);
  const [linkProfileId, setLinkProfileId] = useState("");
  const [manageLinkedProfileModal, setManageLinkedProfileModal] =
    useState(false);
  const [profile, setProfile] = useState({});

  // ** other functions ** //
  const debounceSearch = (search, rowsPerPage, page) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      getEmployeeProfileList(search, rowsPerPage, page);
      timerId = null;
    }, 400);
  };

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmationDialog(false);
  };

  // displays alert message
  const displayAlertMessage = (variant, message) => {
    setDisplayAlert(true);
    setAlertMessage(message);
    setAlertVariant(variant);
  };

  // close alert message
  const closeAlertMessage = () => {
    setDisplayAlert(false);
    setAlertMessage("");
  };

  const resolveList = () => {
    if (isSuperAdmin()) {
      getEmployeeProfileList(search, rowsPerPage, page);
    } else if (isAdmin()) {
      getProfilesAssignedToAdmin(search, rowsPerPage, page);
    } else {
      getProfilesAssignedToUser();
    }
  };

  // make copy of the selected profile
  const makeProfileCopy = (profile_id) => {
    setCopyingProfileLoader({ ...copyingProfile, [profile_id]: true });

    API.post(`admin/employee_profile/${profile_id}/make_copy`)
      .then((res) => {
        const data = res.data;

        if (data.status) {
          displayAlertMessage("success", "Profile Copied Successfully");
          getEmployeeProfileList(search, rowsPerPage, page);
        } else {
          displayAlertMessage("error", "Something went wrong");
        }
      })
      .finally(() => {
        setCopyingProfileLoader({ ...copyingProfile, [profile_id]: false });
      });
  };

  const resolvePaginationChange = (search, rowsPerPage, page) => {
    if (isSuperAdmin()) {
      getEmployeeProfileList(search, rowsPerPage, page);
    } else if (isAdmin()) {
      getProfilesAssignedToAdmin(search, rowsPerPage, page);
    } else {
      getProfilesAssignedToUser(search, rowsPerPage, page);
    }
  };

  const handleChangePage = (e, newPage) => {
    resolvePaginationChange(search, rowsPerPage, newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    resolvePaginationChange(search, e.target.value, page);
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  // ** API Services ** //
  const getEmployeeProfileList = (search, rowsPerPage, page) => {
    const query = {
      search,
      rowsPerPage,
      page: page + 1,
    };
    API.get("/employee-profile/get-employee-list", {
      params: { ...query },
    })
      .then((res) => {
        if (res.status === 200) {
          setList(
            res.data.data?.list?.map((rec) => ({
              ...rec,
              languages: rec.languages?.map((lang) => ({
                name: lang.name?.name,
                value: lang.value,
              })),
            }))
          );
          setTotalRows(res.data.data?.total);
        }
      })
      .catch((err) => console.log(err));
  };

  const getProfilesAssignedToAdmin = (search, rowsPerPage, page) => {
    const query = {
      search,
      rowsPerPage,
      page: page + 1,
    };
    const body = {
      adminId: getUserData().id,
    };
    API.post("/employee-profile/get-profile-assigned-to-admin", body, {
      params: { ...query },
    })
      .then((res) => {
        if (res.status === 200) {
          setList(
            res.data.data?.list?.map((rec) => ({
              ...rec,
              languages: rec.languages?.map((lang) => ({
                name: lang.name?.name,
                value: lang.value,
              })),
            }))
          );
          setTotalRows(res.data.data?.total);
        }
      })
      .catch((err) => console.log(err));
  };

  const getProfilesAssignedToUser = () => {
    const query = {
      search,
      rowsPerPage,
      page: page + 1,
    };
    const body = {
      userId: getUserData().id,
    };
    API.post("/employee-profile/get-profile-assigned-to-user", body, {
      params: { ...query },
    })
      .then((res) => {
        if (res.status === 200) {
          setList(
            res.data.data?.list?.map((rec) => ({
              ...rec,
              languages: rec.languages?.map((lang) => ({
                name: lang.name?.name,
                value: lang.value,
              })),
            }))
          );
          setTotalRows(res.data.data?.total);
        }
      })
      .catch((err) => console.log(err));
  };

  const generatePreview = async (employeeData) => {
    setGeneratingPreview(true);
    setPreviewLoader(true);

    const body = {
      ...employeeData,
      company: employeeData.company?._id || "",
      name: employeeData.name || "",
      timeFrom: employeeData.timeFrom,
      timeTill: employeeData.timeTill,
      skills: employeeData.skills,
      graduationDate: employeeData.graduationDate,
      religion: employeeData.religion?._id,
      nationality: employeeData.nationality?._id,
      expertise: [employeeData.expertise?._id],
      languages: employeeData.languages.map((lang) => ({
        value: lang.value,
        name: lang.name,
      })),
    };

    const result = await getEmployeeProfilePic(body._id);

    body.profilePic = result.data.data?.profile_pic;

    delete body._id;
    delete body.profile_pic;
    delete body.deleted;
    delete body.createdAt;
    delete body.updatedAt;
    delete body.__v;
    delete body.createdBy;

    API.post("admin/employee_profile/generate_preview", body, {
      responseType: "arraybuffer",
    })
      .then((res) => {
        if (res.headers["content-type"] == "application/pdf") {
          // display pdf preview
          let blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          setPreviewSourcePath(URL.createObjectURL(blob));
          setProfilePreviewDialog(true);
        } else {
          // handle error
          try {
            let response = JSON.parse(
              String.fromCharCode.apply(null, new Uint8Array(res.data))
            );

            if (!response.status) {
              displayAlertMessage("error", "Something went wrong");
            }
          } catch (error) {
            displayAlertMessage("error", "Something went wrong");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setPreviewLoader(false);
        setGeneratingPreview(false);
      });
  };

  // delete record
  const handleDelete = () => {
    if (deleteProfile) {
      API.delete("/employee-profile/delete", {
        params: { id: deleteProfile._id },
      }).then((res) => {
        if (res.status === 200 && res.data.status) {
          setDeleteAlert(true);
          resolveList();
        }
      });
    }
  };

  const getEmployeeProfilePic = async (id) => {
    const params = { id };
    return await API.get("/employee-profile/get-employee-profile-pic", {
      params,
    });
  };

  useEffect(() => {
    resolveList();
  }, []);

  return (
    <div>
      <Grid container rowSpacing={0}>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <Typography variant="body1" sx={{ fontSize: "20px" }} color="#474747">
            Employee Profile Management
          </Typography>
        </Grid>
        <Grid item xs={12} px={"25px"} sx={{ backgroundColor: "white", py: 3 }}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems="stretch"
            spacing={2}
            sx={{ mb: 3 }}
          >
            <Box sx={{ display: "flex", alignItems: "stretch", gap: 2 }}>
              <TextField
                id="outlined-search"
                size="small"
                placeholder="Search"
                type="search"
                className="custom-input"
                sx={{
                  width: isMobile ? 2 / 3 : "100%",
                }}
                onChange={(e) => {
                  debounceSearch(e.target.value, rowsPerPage, page);
                  setSearch(e.target.value);
                }}
              ></TextField>
            </Box>
            {isSuperAdmin() || isAdmin() ? (
              <Button
                variant="contained"
                color="primary"
                sx={{ borderRadius: 0, textTransform: "capitalize" }}
                onClick={() => navigate("/employee-profiles/create")}
                disableRipple
              >
                Create New Profile
              </Button>
            ) : null}
          </Stack>

          <TableContainer component={Paper} elevation={0}>
            <Table aria-label="simple table" className={"custom-table br-0"}>
              <TableHead sx={{ backgroundColor: "#f4f6f9" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      width: "80px",
                      minWidth: "80px",
                      textAlign: "center",
                    }}
                  >
                    No.
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Company Name</TableCell>
                  <TableCell sx={{ width: "80px", minWidth: "80px" }}>
                    Created By
                  </TableCell>
                  <TableCell sx={{ width: "80px", minWidth: "80px" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              {!list?.length ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography sx={{ width: "100%", textAlign: "center" }}>
                        No Data Available
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {list.map((row, index) => (
                    <TableRow key={row._id}>
                      <TableCell style={{ textAlign: "center" }}>
                        {page * rowsPerPage + (index + 1)}
                      </TableCell>
                      <TableCell style={{ width: "25%" }}>{row.name}</TableCell>
                      <TableCell>{row.company.name}</TableCell>
                      <TableCell sx={{ width: "180px", minWidth: "80px" }}>
                        {row.createdBy.name}
                      </TableCell>
                      <TableCell sx={{ width: "80px", minWidth: "80px" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {isAdmin() ? (
                            <>
                              <Tooltip title="Manage Linked Users">
                                <IconButton
                                  color="inherit"
                                  sx={{ padding: 0, marginLeft: "5px" }}
                                  onClick={() => {
                                    if (
                                      !row.assignedToUsers?.length &&
                                      !row.assignedToAdmins?.length
                                    ) {
                                      setAlertVariant("error");
                                      setAlertMessage(
                                        "No user or admin linked to the profile!"
                                      );
                                      setDisplayAlert(true);
                                      return;
                                    }
                                    setManageLinkedProfileModal(true);
                                    setProfile(row);
                                  }}
                                >
                                  <Link fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Link Profile">
                                <IconButton
                                  color="inherit"
                                  sx={{ padding: 0, marginLeft: "5px" }}
                                  onClick={() => {
                                    setLinkProfileModal(true);
                                    setLinkProfileId(row._id || "");
                                  }}
                                >
                                  <AddLink fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              {!copyingProfile[row._id] ? (
                                <Tooltip title="Copy Record">
                                  <IconButton
                                    color="inherit"
                                    sx={{ padding: 0, marginLeft: "5px" }}
                                    onClick={() => {
                                      makeProfileCopy(row._id);
                                    }}
                                  >
                                    <ContentCopy fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <CircularProgress
                                  color="primary"
                                  size="20px"
                                  sx={{ marginLeft: "4px", marginRight: "5px" }}
                                />
                              )}
                            </>
                          ) : null}
                          <Tooltip title="Preview">
                            <IconButton
                              color="inherit"
                              sx={{ padding: 0, marginLeft: "5px" }}
                              onClick={() => generatePreview(row)}
                            >
                              <Visibility fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit">
                            <IconButton
                              color="inherit"
                              sx={{ padding: 0, marginLeft: "5px" }}
                              onClick={() => {
                                navigate(`/employee-profiles/edit/${row._id}`);
                              }}
                            >
                              <Edit fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          {isAdmin() ? (
                            <Tooltip title="Delete">
                              <IconButton
                                color="inherit"
                                sx={{ padding: 0, marginLeft: "5px" }}
                                onClick={() => {
                                  setDeleteProfile(row);
                                  setDeleteConfirmationDialog(true);
                                }}
                              >
                                <Delete fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    count={totalRows}
                    colSpan={5}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={displayAlert}
        onClose={closeAlertMessage}
        message={displayAlert}
        key={"system-message"}
        autoHideDuration={2000}
      >
        <Alert severity={alertVariant}>
          {alertMessage}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            sx={{ ml: 2 }}
            onClick={closeAlertMessage}
          >
            <Close fontSize="small" />
          </IconButton>
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={deleteAlert}
        onClose={() => {
          setDeleteAlert(false);
          getEmployeeProfileList(search, rowsPerPage, page);
        }}
        key={"system-message"}
        autoHideDuration={2000}
      >
        <Alert severity={"success"}>Profile Deleted Successfully</Alert>
      </Snackbar>
      <Dialog open={deleteConfirmationDialog}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {deleteProfile.name}'s profile?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDeleteConfirmationClose()}>Close</Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              handleDeleteConfirmationClose();
              handleDelete();
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <ProfilePreviewDialog
        open={showProfilePreviewDialog}
        setOpen={setProfilePreviewDialog}
        sourcePath={previewSourcePath}
      />
      <Modal open={previewLoader}>
        <Box
          sx={{
            display: "flex",
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px 30px",
              borderRadius: "10px",
              textAlign: "center",
            }}
          >
            <CircularProgress />
          </div>
        </Box>
      </Modal>
      {linkProfileModal ? (
        <LinkProfileModal
          open={linkProfileModal}
          setOpen={setLinkProfileModal}
          profileId={linkProfileId}
          setProfileId={setLinkProfileId}
          setAlert={setDisplayAlert}
          setAlertMessage={setAlertMessage}
          setAlertVariant={setAlertVariant}
          getList={resolveList}
        />
      ) : null}
      {manageLinkedProfileModal ? (
        <ManageLinkedProfileModal
          open={manageLinkedProfileModal}
          setOpen={setManageLinkedProfileModal}
          setAlert={setDisplayAlert}
          setAlertMessage={setAlertMessage}
          setAlertVariant={setAlertVariant}
          data={profile}
          getList={resolveList}
        />
      ) : null}
    </div>
  );
};

export default List;
