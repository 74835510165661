import { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    let user = localStorage.getItem("cvg_user");

    if (user) {
      try {
        setUser(user);
      } catch (error) {
        console.log("error while parsing data");
      }
    }
  }, []);

  const login = (user) => {
    localStorage.setItem("cvg_user", user);
    setUser(user);
  };

  const logout = () => {
    localStorage.removeItem("cvg_user");
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
