import React from 'react';
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom"

export const NotFound = () => {
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate('/', { replace: true });
  }

  return (
    <Box sx={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#F6F7FA' }}>
      <Box sx={{ textAlign: 'center' }}>
      <Typography
          component="h1"
          sx={{ color: "#969696", py: 5, textAlign: "center", fontSize: {
            xs: 40,
            md: 60
          } }}
        >
          <b>404, Not Found</b>
        </Typography>
        <Button variant="contained" onClick={navigateToHome}>
          Go Back To Home
        </Button>
      </Box>
    </Box>
  )
}
