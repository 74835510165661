import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Card, CardContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  TextField,
  Button,
  Link,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import loginImage from "../../assets/login.svg";
import { ImageListItem } from "@mui/material";
import API from "../../config/axios";
import { useAuth } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const redirectPath = location.state?.path || "/";

  useEffect(() => {
    if (auth?.user) {
      navigate(redirectPath, { replace: true });
    }
  }, [auth?.user]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [inputErrors, setInputErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      loginUser();
    }
  };

  const loginUser = async () => {
    await API.post("auth/login", {
      email: email,
      password: password,
    }).then((res) => {
      let data = res.data;
      setInputErrors({});

      if (data.status) {
        data.admin.tokens = data.tokens;
        auth.login(JSON.stringify(data.admin));
        API.defaults.headers.common = {
          Authorization: "Bearer " + data.tokens.access.token,
        };
        navigate("/employee-profiles/list", { replace: true });
      } else {
        if (data && data.type === "validation" && data.error.length > 0) {
          data.error.forEach((error) => {
            setInputErrors((prevState) => {
              return { [error.field]: error.message, ...prevState };
            });
          });
        } else {
          alert("seomthing else error");
        }
      }
    });
  };

  return (
    <Box sx={{ backgroundColor: "#F6F7FA", minHeight: "100vh" }}>
      <Container sx={{ backgroundColor: "inherit", pb: 8 }}>
        <Typography
          component="h1"
          sx={{
            color: "#D8E1EE",
            py: 7,
            textAlign: "center",
            fontSize: {
              xs: 20,
              md: 30,
            },
          }}
        >
          <b>NLS EMPLOYEE PROFILE PORTAL</b>
        </Typography>

        <Card
          elevation={0}
          sx={{
            mx: {
              sm: 3,
              md: 7,
            },
          }}
        >
          <CardContent sx={{ p: 0, paddingBottom: "0px !important" }}>
            <Grid container>
              <Grid
                item
                xs={0}
                sm={6}
                md={7}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#F9FAFB",
                  padding: 3,
                }}
              >
                <ImageListItem sx={{ display: { xs: "none", sm: "block" } }}>
                  <img src={loginImage} alt="login" loading="lazy" />
                </ImageListItem>
              </Grid>
              <Grid item xs={12} sm={6} md={5} p={5}>
                <Box>
                  <Typography
                    variant="h6"
                    component="h1"
                    sx={{ color: "#555555", py: 3, textAlign: "center" }}
                  >
                    <b>Login</b>
                  </Typography>
                  <TextField
                    id="demo-helper-text-misaligned-no-helper-1"
                    placeholder="Email"
                    fullWidth
                    autoFocus={true}
                    size="small"
                    margin="dense"
                    value={email}
                    error={inputErrors.email ? true : false}
                    helperText={inputErrors.email || " "}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  <OutlinedInput
                    id="demo-helper-text-misaligned-no-helper-2"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    fullWidth
                    size="small"
                    margin="dense"
                    value={password}
                    error={inputErrors.password ? true : false}
                    helperText={inputErrors.password || " "}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowPassword((prevState) => !prevState)
                          }
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <Button
                    variant="contained"
                    disableRipple
                    fullWidth
                    size="large"
                    sx={{ my: 5, py: "8px" }}
                    onClick={() => loginUser()}
                  >
                    Login
                  </Button>
                  <Box sx={{ textAlign: "center", mb: 4 }}>
                    <Link
                      underline="none"
                      sx={{ mx: "auto", cursor: "pointer" }}
                      onClick={() => {
                        navigate("/login/forgot-password");
                      }}
                    >
                      Forgot Password
                    </Link>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}
