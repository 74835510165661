import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ImageListItem from "@mui/material/ImageListItem";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import LockResetIcon from "@mui/icons-material/LockReset";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./MainLayout.css";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/auth";
import { isAdmin, isSuperAdmin, getUserData } from "../utils/utilities";

const protectedModules = [
  {
    label: "Company Management",
    route: "/companies",
  },
  {
    label: "Expertise Management",
    route: "/expertises",
  },
  {
    label: "Language Management",
    route: "/languages",
  },
  {
    label: "Nationality Management",
    route: "/nationalities",
  },
  {
    label: "Religion Management",
    route: "/religions",
  },
  {
    label: "Tools And Tech",
    route: "/tools",
  },
];

const drawerWidth = 240;

export default function MainLayout(props) {
  let navigate = useNavigate();
  const { window } = props;
  const auth = useAuth();
  const location = useLocation();
  const [modules, setModules] = React.useState([
    {
      label: "Profile Management",
      route: "/employee-profiles/list",
    },
  ]);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const openProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeProfileMenu = () => {
    setAnchorEl(null);
  };

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const naviagateToPage = (event, index) => {
    setSelectedIndex(index);
    let module = modules[index];
    navigate(module.route);
  };

  const handleLogout = () => {
    auth.logout();
  };

  React.useEffect(() => {
    let index = modules.findIndex((m) => {
      return m.route === location.pathname;
    });

    if (index !== -1) {
      setSelectedIndex(index);
    }
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar>
        <ImageListItem
          sx={{
            mx: "auto",
            my: 3,
          }}
        >
          <img
            src="/Nexuslink Logo.png"
            alt="Nexuslink Services Logo"
            loading="lazy"
            height={70}
          />
        </ImageListItem>
      </Toolbar>
      <Divider />
      <List component="nav">
        {modules.map((module, index) => (
          <ListItemButton
            key={module.route}
            selected={selectedIndex === index}
            onClick={(event) => naviagateToPage(event, index)}
            className="side-nav-link"
          >
            <ListItemText
              sx={{
                height: "100%",
                width: "100%",
              }}
            >
              <Typography variant="body1" noWrap sx={{ fontSize: "14px" }}>
                {selectedIndex === index ? <b>{module.label}</b> : module.label}
              </Typography>
            </ListItemText>
          </ListItemButton>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  React.useEffect(() => {
    if (isSuperAdmin()) {
      setModules((prevState) => [
        ...prevState,
        ...protectedModules,
        { label: "User Management", route: "/users" },
      ]);
    } else if (isAdmin()) {
      setModules((prevState) => [
        ...prevState,
        ...protectedModules,
        // { label: "User Management", route: "/users" },
      ]);
    }
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="body1"
            noWrap
            component="div"
            sx={{ flexGrow: 1 }}
          >
            <b>NLS Employee Profile Portal</b>
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ marginTop: "10px" }}>{getUserData().name || ""}</Box>
            <Tooltip title="Account settings">
              <IconButton
                onClick={openProfileMenu}
                size="small"
                sx={{ ml: 1 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar
                  alt="Cindy Baker"
                  variant="dense"
                  src="/static/images/avatar/3.jpg"
                  sx={{ bgcolor: "grey", width: 32, height: 32 }}
                >
                  {(getUserData().name || "")?.slice(0, 1)?.toUpperCase()}
                </Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={closeProfileMenu}
            onClick={closeProfileMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              onClick={() => {
                navigate("/user-profile");
                setSelectedIndex(null);
              }}
            >
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              Profile
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/change-password");
                setSelectedIndex(null);
              }}
            >
              <ListItemIcon>
                <LockResetIcon />
              </ListItemIcon>
              Change Password
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: "#f4f6f9",
          minHeight: "100vh",
          width: "100%",
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
