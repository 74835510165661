import { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Select,
  MenuItem,
  Button,
  Grid,
  FormControl,
  FormHelperText,
  Stack,
  Paper,
  IconButton,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import axios from "../../../config/axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "100%",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  outline: "none",
  p: 4,
};

const AddLanguageModal = (props) => {
  // modal states
  const [languageData, setLanguageData] = useState({});
  const [validations, setValidations] = useState({
    language: { status: false, msg: "" },
  });
  const [newLanguage, setNewLanguage] = useState("");
  const [alertMsg, setAlertMsg] = useState({});

  // other functions
  const handleSubmit = () => {
    if (isFormValid()) {
      props.setEmployeeData((prevState) => {
        return {
          ...prevState,
          languages: [...prevState.languages, languageData],
        };
      });
      props.setOpen({ ...props.open, addLanguage: false });
      setLanguageData({});
    }
  };

  const isFormValid = () => {
    console.log(languageData);
    if (!languageData?.id || !languageData.id.length) {
      setValidations(() => ({
        language: { status: true, msg: "Required!" },
      }));
      return false;
    }
    // check if already exists
    const langs = props.employeeData?.languages?.map((lang) => lang.id);
    if (langs.includes(languageData?.id)) {
      setValidations(() => ({
        language: { status: true, msg: "Already Exists" },
      }));
      return false;
    }
    return true;
  };

  const addLanguage = () => {
    const body = { name: newLanguage };
    axios
      .post("/admin/languages", body)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status) {
            setAlertMsg({ status: true, msg: "Language added successfully!" });
            setValidations({});
            setNewLanguage("");
            props.fetchLanguageOptions();
          } else {
            if (res.data.type === "validation" && res.data.error) {
              const tempValidations = {};
              res.data.error.forEach((val) => {
                tempValidations.newLanguage = {
                  status: true,
                  msg: val.message,
                };
              });
              setValidations((prevState) => ({
                ...prevState,
                ...tempValidations,
              }));
            }
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal open={props.open.addLanguage}>
      <Box style={style}>
        <Grid container sx={{ justifyContent: "space-around" }}>
          <Grid xs={8} sm={6} md={5} xl={4} lg={3} item>
            <Paper style={{ ...props.style, width: "100%" }} elevation={0}>
              <Box sx={{ padding: "10px" }}>
                <Grid container>
                  <Grid xs={11} sm={11} md={11} lg={11} xl={11} item>
                    <Box style={{ paddingBottom: "10px" }}>
                      <Typography variant="h5">Add Language</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    xs={1}
                    sm={1}
                    md={1}
                    lg={1}
                    xl={1}
                    item
                    sx={{ textAlign: "right" }}
                  >
                    <IconButton
                      style={{ paddingTop: "0px", paddingRight: "0px" }}
                      onClick={() =>
                        props.setOpen({ ...props.open, addLanguage: false })
                      }
                    >
                      <Close color="error" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Stack spacing={1}>
                  <Grid container spacing={1}>
                    <Grid xs={11} sm={11} md={11} lg={11} xl={11} item>
                      <Grid container spacing={1}>
                        <Grid xs={9} sm={9} md={9} lg={9} xl={10} item>
                          <TextField
                            size="small"
                            placeholder={"Language"}
                            value={newLanguage}
                            onChange={(e) => setNewLanguage(e.target.value)}
                            error={validations.newLanguage?.status}
                            helperText={validations.newLanguage?.msg}
                            fullWidth
                          />
                        </Grid>
                        <Grid xs={3} sm={3} md={3} lg={3} xl={2} item>
                          <Button
                            variant="contained"
                            onClick={addLanguage}
                            sx={{ width: "100%" }}
                          >
                            add
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={11} sm={11} md={11} lg={11} xl={11} item>
                      <FormControl
                        error={validations.language?.status}
                        sx={{ width: "100%" }}
                      >
                        <Select
                          size="small"
                          value={languageData.name}
                          onChange={(e) => {
                            const opt = props.languageOptions.filter(
                              (lang) => lang.id === e.target.value
                            );
                            setLanguageData({ ...opt[0], value: 3 });
                          }}
                          displayEmpty={true}
                          renderValue={() => {
                            const styles = {};
                            const value = languageData.name
                              ? props.languageOptions?.filter(
                                  (opt) => opt.id === languageData.id
                                )[0]?.name
                              : "Select";
                            if (value === "Select") {
                              styles.color = "#919fa5";
                            }
                            return <div style={styles}>{value}</div>;
                          }}
                        >
                          {props.languageOptions?.map((lang) => (
                            <MenuItem value={lang.id}>{lang.name}</MenuItem>
                          ))}
                        </Select>
                        {validations.language?.status ? (
                          <FormHelperText>
                            {validations.language?.msg}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Stack>
                <Box sx={{ paddingTop: "40px" }}>
                  <Button variant="contained" onClick={(e) => handleSubmit()}>
                    Submit
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Snackbar
          open={alertMsg.status}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={2000}
          onClose={() => setAlertMsg({})}
        >
          <Alert severity={alertMsg.severity} sx={{ width: "100%" }}>
            {alertMsg.msg}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
};

export default AddLanguageModal;
