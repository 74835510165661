import { useState, useEffect } from "react";
import axios from "../../../config/axios";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Snackbar,
  Alert,
  Button,
  Grid,
  Stack,
  Paper,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "100%",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  outline: "none",
  p: 4,
};

const AddExpertiseModal = (props) => {
  const [validations, setValidations] = useState({
    name: { status: false, msg: "" },
  });
  const [skills, setSkills] = useState([]);
  const [name, setName] = useState("");
  const [newSkill, setNewSkill] = useState("");
  const [alertMsg, setAlertMsg] = useState({ status: false });

  // other functions
  const handleSubmit = () => {
    addExpertise();
  };

  const handleAlertClose = () => {
    setAlertMsg({ status: false, msg: "", severity: "" });
    props.setOpen((prevState) => ({ ...prevState, addExpertise: false }));
  };

  const handleAlert = (severity, msg) => {
    setAlertMsg({ status: true, msg, severity });
  };

  // ** API Services **//
  const addExpertise = () => {
    const body = { name: name, skills };
    axios
      .post("/admin/expertises", body)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status) {
            setValidations({});
            setNewSkill("");
            setSkills([]);
            setName("");
            props.fetchExpertiseOptions();
            handleAlert("success", "Expertise added successfully!");
          }
          if (!res.data.status && res.data.type === "validation") {
            setValidations({
              name: { status: true, msg: res.data.msg },
            });
          }
          if (!res.data.status && res.data.error) {
            const tempValidations = {};
            res.data.error.forEach((err) => {
              tempValidations[err.field] = { status: true, msg: err.message };
            });
            setValidations({ ...tempValidations });
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal open={props.open.addExpertise}>
      <Box style={style}>
        <Grid container sx={{ justifyContent: "space-around" }}>
          <Grid xs={8} sm={6} md={6} xl={4} lg={4} item>
            <Paper style={{ ...props.style, width: "100%" }} elevation={0}>
              <Box sx={{ padding: "10px" }}>
                <Grid container>
                  <Grid xs={11} sm={11} md={11} lg={11} xl={11} item>
                    <Box style={{ paddingBottom: "10px" }}>
                      <Typography variant="h5">Add Expertise</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    xs={1}
                    sm={1}
                    md={1}
                    lg={1}
                    xl={1}
                    item
                    sx={{ textAlign: "right" }}
                  >
                    <IconButton
                      style={{ paddingTop: "0px", paddingRight: "0px" }}
                      onClick={() =>
                        props.setOpen({ ...props.open, addExpertise: false })
                      }
                    >
                      <Close color="error" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Stack spacing={1}>
                  <Grid container>
                    <Grid xs={11} sm={11} md={11} lg={11} xl={11} item>
                      <TextField
                        required
                        size="small"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          if (validations.name?.status) {
                            setValidations((prevState) => ({
                              ...prevState,
                              name: { status: false, msg: "" },
                            }));
                          }
                        }}
                        error={validations.name?.status}
                        helperText={validations.name?.msg}
                        sx={{ width: "100%" }}
                        placeholder="Expertise"
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid xs={8} sm={8} md={9} lg={9} xl={9} item>
                      <TextField
                        size="small"
                        value={newSkill}
                        onChange={(e) => {
                          setNewSkill(e.target.value);
                          if (validations.newSkill?.status) {
                            setValidations((prevState) => ({
                              ...prevState,
                              newSkill: { status: false, msg: "" },
                            }));
                          }
                        }}
                        error={validations.skills?.status}
                        helperText={validations.skills?.msg}
                        sx={{ width: "100%" }}
                        placeholder="Skill"
                      />
                    </Grid>
                    <Grid
                      xs={3}
                      sm={3}
                      md={2}
                      lg={2}
                      xl={2}
                      item
                      sx={{ paddingLeft: "5px" }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => {
                          if (newSkill.length) {
                            setSkills((prevState) => [...prevState, newSkill]);
                            setNewSkill("");
                            if (validations.skills?.status) {
                              setValidations((prevState) => ({
                                ...prevState,
                                skills: { status: false, msg: "" },
                              }));
                            }
                          } else {
                            setValidations((prevState) => ({
                              ...prevState,
                              skills: { status: true, msg: "Required!" },
                            }));
                          }
                        }}
                        sx={{ width: "100%", maringLeft: "50px" }}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      xs={11}
                      sm={11}
                      md={11}
                      lg={11}
                      xl={11}
                      item
                      sx={{ display: "flex", flexWrap: "wrap" }}
                    >
                      {skills.map((sk) => (
                        <Button
                          variant={
                            skills.includes(sk) ? "contained" : "outlined"
                          }
                          sx={{ textTransform: "unset" }}
                          onClick={() => {
                            const index = skills.indexOf(sk);

                            if (index < 0) {
                              setSkills((prevState) => [...prevState, sk]);
                            } else {
                              setSkills((prevState) => {
                                prevState.splice(index, 1);
                                return [...prevState];
                              });
                            }
                          }}
                        >
                          {sk}
                        </Button>
                      ))}
                    </Grid>
                  </Grid>
                </Stack>
                <Box sx={{ paddingTop: "40px" }}>
                  <Button variant="contained" onClick={(e) => handleSubmit()}>
                    Submit
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Snackbar
          open={alertMsg.status}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={2000}
          onClose={handleAlertClose}
        >
          <Alert severity={alertMsg.severity} sx={{ width: "100%" }}>
            {alertMsg.msg}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
};

export default AddExpertiseModal;
